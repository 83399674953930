import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { StoryblokComponent } from "storyblok-js-client";
import DynamicComponent from "../../../DynamicComponent";

interface GridSchema {
  columns: StoryblokComponent<string>[];
}

interface GridProps {
  blok: GridSchema;
}

const Grid: FC<GridProps> = ({ blok }) => {
  return (
    <div className="grid" {...sbEditable(blok)}>
      {blok.columns.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
};

export default Grid;
