import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";

interface Schema {
  html: string;
}

interface Props {
  blok: Schema;
}

const BlokHTML: FC<Props> = ({ blok }) => {
  return (
    <div
      {...sbEditable(blok)}
      {...{
        dangerouslySetInnerHTML: { __html: blok.html },
      }}
    />
  );
};

export default BlokHTML;
