import { FC, useEffect, useRef, useState } from "react";
import { InputText } from "../form/inputs/text/InputText";
import { Button, ButtonPresets } from "../button/Button";
import { emptyString } from "../content/Content";
import { sbEditable } from "@storyblok/storyblok-editable";
import { useMediaQuery } from "usehooks-ts";

interface Blok {
  name: string;
}

interface PageProps {
  blok: Blok;
}

const Newsletter: FC<PageProps> = ({ blok }) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [done, setDone] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const mobile = useMediaQuery("(max-width: 1000px)");
  useEffect(() => {
    if (localStorage.getItem("newsletter") === "true") {
      setDone(true);
    }
  }, []);
  return (
    <div {...sbEditable(blok)} className="feedbackForm">
      <form
        ref={formRef}
        name={blok.name}
        {...{
          netlify: "true",
          "netlify-honeypot": "bot-field",
        }}
        onSubmit={(e) => {
          setEmail(undefined);
          localStorage.setItem("newsletter", "true");
          setDone(true);
        }}
      >
        <div
          style={{
            marginTop: "32px",
            marginBottom: mobile ? "32px" : 0,
            display: "flex",
            alignItems: "flex-end",
            gap: 32,
            flexWrap: "wrap",
            width: "100%",
            // width: "100%",
          }}
        >
          {done ? (
            <p style={{ color: "#fff" }}>
              Thankyou for joining the newsletter {":)"}
            </p>
          ) : (
            <>
              <div style={{ display: "flex", flex: 1 }}>
                <InputText
                  textColor="#fff"
                  slug={"email"}
                  label={"Email address"}
                  required={true}
                  type={"email"}
                  onChange={(email: string) => {
                    setEmail(email);
                  }}
                />
              </div>
              {!emptyString(email) && email?.indexOf("@") !== -1 ? (
                <Button
                  fullWidthMobile={true}
                  disabled={emptyString(email) && email?.indexOf("@") !== -1}
                  preset={ButtonPresets.primary}
                  label="Submit"
                  onClick={() => {
                    formRef.current?.submit();
                  }}
                />
              ) : null}
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default Newsletter;
