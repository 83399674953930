import { FC, useEffect, useRef, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./Graphic.module.scss";

interface Schema {
  image: any;
  style: string;
  className: string;
  id: string;

  insetXS: string;
  insetS: string;
  insetM: string;
  insetL: string;
  insetXL: string;

  translateXS: string;
  translateS: string;
  translateM: string;
  translateL: string;
  translateXL: string;

  rotateXS: string;
  rotateS: string;
  rotateM: string;
  rotateL: string;
  rotateXL: string;

  zIndexXS: string;
  zIndexS: string;
  zIndexM: string;
  zIndexL: string;
  zIndexXL: string;

  heightXS: string;
  heightS: string;
  heightM: string;
  heightL: string;
  heightXL: string;

  widthXS: string;
  widthS: string;
  widthM: string;
  widthL: string;
  widthXL: string;
}

interface Props {
  blok: Schema;
}

const TYPES = ["inset", "translate", "rotate", "zIndex", "width", "height"];
const SIZES = ["S", "XS", "M", "L", "XL"];

const BlokGraphic: FC<Props> = ({ blok }) => {
  const id = randomUUID();
  const comp = useRef(null);

  let styleMap = {};
  const props: any = blok;
  const tempStyleMap: any = {};
  TYPES.map((type: string) => {
    SIZES.map((size: string) => {
      const key: string = type + size;

      if (props[key] === "") return;
      if (props[key] === null) return;
      if (props[key] === undefined) return;
      tempStyleMap["--" + type + size] = props[key];
    });
  });
  styleMap = tempStyleMap;

  return (
    <div
      className={styles.Graphic + ` ${blok.className}`}
      ref={comp}
      id={`graphic_${id}`}
      {...sbEditable(blok)}
      style={styleMap}
    >
      {/* <style>
        {`
        #graphic_${id}{
          ${blok.style}

         
        }

        `}
      </style> */}
      <img
        id={blok.id}
        alt={blok.image.alt}
        className={styles.image}
        src={blok.image.filename}
      />
    </div>
  );
};

function randomUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export default BlokGraphic;
