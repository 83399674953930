import { FC, useEffect, useRef, useState } from "react";
import styles from "./ImageSlideShow.module.scss";
import Icon from "../icon/Icon";
import { useSwipeable } from "react-swipeable";
import BlokImage from "../image/Image";
import { useMediaQuery } from "usehooks-ts";
import { useMediaQueryNew } from "../blogPost/BlogPage";

interface Schema {
  items: any;
}

interface Props {
  blok: Schema;
}

const BlokImageSlideShow: FC<Props> = ({ blok }) => {
  const matches = useMediaQueryNew("(min-width: 1000px)");

  const [imageHeight, setImageHeight] = useState("545px");
  const [slides, setSlides] = useState(blok.items);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setImageHeight(matches ? "545px" : "300px");
  }, [matches]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Left") {
        goRight();
        eventData.event.preventDefault();
        return false;
      }
      if (eventData.dir === "Right") {
        goLeft();
        eventData.event.preventDefault();
        return false;
      }
    },
  });

  const goLeft = () => {
    let newIndex = currentIndex - 1;
    if (currentIndex <= 0) {
      newIndex = slides.length - 1;
    }
    setCurrentIndex(newIndex);
  };

  const goRight = () => {
    let newIndex = currentIndex + 1;
    if (currentIndex >= slides.length - 1) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };

  const slidesStyle: any = { "--currentSide": currentIndex };
  return (
    <div className={styles.ImageSlideShow} data-debug={false} {...handlers}>
      <div
        className={styles.slides}
        data-slide-index={currentIndex}
        style={slidesStyle}
      >
        {slides.map((slide: any, index: number) => {
          return (
            <div
              className={styles.slideWrapper}
              key={`slide_${index}`}
              data-active={currentIndex === index}
              data-index={index}
            >
              <div className={styles.slide}>
                <BlokImage
                  blok={{
                    ...slide,
                    ...{
                      width: "100%",
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.actions}>
        <div
          className={styles.left}
          onClick={() => goLeft()}
          title={"Scroll Left"}
        >
          <Icon name={"arrowleft"} />
        </div>
        <div className={styles.count}>
          {currentIndex + 1} / {slides.length}
        </div>
        <div
          className={styles.right}
          onClick={() => goRight()}
          title={"Scroll Right"}
        >
          <Icon name={"arrowright"} />
        </div>
      </div>
    </div>
  );
};

export default BlokImageSlideShow;

function arrayRotate(arr: any, reverse: boolean) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
}
