import React, { useCallback, useRef } from "react";
import styles from "./Swiper.module.scss";
import { sbEditable } from "@storyblok/storyblok-editable";
import {
  Navigation,
  Pagination,
  Scrollbar,
  FreeMode,
  Autoplay,
  Thumbs,
  EffectCards,
  EffectCoverflow,
  EffectCube,
  EffectCreative,
  EffectFade,
  EffectFlip,
  Grid,
  A11y,
  Keyboard,
} from "swiper";

import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import "swiper/css/effect-cube";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/effect-cards";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import "swiper/css/autoplay";

// import "swiper/css/effect-none";

import { StoryblokComponent } from "storyblok-js-client";
import DynamicComponent from "../../../DynamicComponent";
import { emptyString } from "../content/Content";
import Icon from "../icon/Icon";

interface Schema {
  bloks: StoryblokComponent<string>[];
  className: string;
  gap: string;
  slidesPerView: number | "auto";
  autoplay: string;
  rows: string;

  effect:
    | "cube"
    | "fade"
    | "flip"
    | "coverflow"
    | "creative"
    | "cards"
    | "none";

  showPagination: boolean;
  showScrollbar: boolean;
  showArrows: boolean;
  thumbails: boolean;
  autoHeight: boolean;
  rewind: boolean;
  swipable: boolean;
  allowKeyboard: boolean;
  infinite: boolean;
  gridMode: boolean;
  freeMode: boolean;
  centered: boolean;
  verticalMode: boolean;
}

interface Props {
  blok: Schema;
}

export default function BlokSwiper({ blok }: Props) {
  const sliderRef = useRef(null);

  const {
    bloks,
    className,
    gap,
    rows,
    slidesPerView,
    autoplay,
    autoHeight,
    effect,
    showArrows,
    showPagination,
    showScrollbar,
    thumbails,
    rewind,
    swipable,
    allowKeyboard,
    infinite,
    gridMode,
    freeMode,
    centered,
    verticalMode,
  } = blok;
  const modules = [A11y];
  const props: SwiperProps = {
    spaceBetween: gap,
    slidesPerView: slidesPerView,
    autoplay: emptyString(autoplay)
      ? false
      : {
          delay: Number(autoplay),
          disableOnInteraction: true,
        },
    grid: gridMode ? { rows: Number(rows) } : undefined,
    autoHeight: autoHeight,
    effect: effect === "none" ? undefined : effect,
    allowTouchMove: swipable,
    rewind: rewind,
    keyboard: allowKeyboard,
    loop: infinite,
    freeMode: freeMode,
    centeredSlides: centered,
    direction: verticalMode ? "vertical" : "horizontal",
    navigation: showArrows
      ? {
          nextEl: `.${styles.arrowNext}`,
          prevEl: `.${styles.arrowPrev}`,
        }
      : false,
    pagination: showPagination ? { clickable: true } : false,
  };

  if (showPagination) modules.push(Pagination);
  if (showArrows) modules.push(Navigation);
  if (showScrollbar) modules.push(Scrollbar);
  if (gridMode) modules.push(Grid);
  if (freeMode) modules.push(FreeMode);
  if (autoplay) modules.push(Autoplay);
  if (thumbails) modules.push(Thumbs);
  if (effect === "cards") modules.push(EffectCards);
  if (effect === "coverflow") modules.push(EffectCoverflow);
  if (effect === "cube") modules.push(EffectCube);
  if (effect === "creative") modules.push(EffectCreative);
  if (effect === "fade") modules.push(EffectFade);
  if (effect === "flip") modules.push(EffectFlip);
  if (allowKeyboard) modules.push(Keyboard);

  return (
    <div
    // style={{
    //   padding: "12px 0",
    // }}
    >
      <div
        {...sbEditable(blok)}
        className={styles.Swiper + ` ${className}`}
        style={{
          width: "100%",
        }}
        data-has-arrows={showArrows}
      >
        {showArrows && (
          <div className={styles.arrowPrev + " " + styles.arrow}>
            <Icon name="ArrowBack" />
          </div>
        )}

        <Swiper
          modules={modules}
          // ref={sliderRef}
          {...props}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          {bloks?.map((childBlok: any, index: number) => (
            <SwiperSlide key={`slide_${index}`}>
              <DynamicComponent blok={childBlok} key={index} />
            </SwiperSlide>
          ))}
        </Swiper>
        {showArrows && (
          <div className={styles.arrowNext + " " + styles.arrow}>
            <Icon name="ArrowForward" />
          </div>
        )}
      </div>
    </div>
  );
}
