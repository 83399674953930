import React, { FC } from "react";

interface Props {
  type?: string;
  fontFamily?: string;
  fontWeight?: string;
  fontSize?: string;
  textColor?: string;
  iconColor?: string;
  icon?: any;
  gap?: any;
  topHeader?: any;
  children?: any;
}

/**
 * Primary UI component for user interaction
 */
export const ListComp: FC<Props> = (props) => {
  const style = {
    normal: {
      color: props.textColor,
      fontFamily: props.fontFamily,
      fontWeight: props.fontWeight,
      fontSize: props.fontSize,
    },
    hover: {},
    list: {
      gap: props.gap?.toString().endsWith("px") ? props.gap : props.gap + "px",
      "--list-icon-color": props.iconColor,
      "--list-icon-content": "'" + props.icon + "'",
    },
  };

  let type = props.type
    ? props.type.charAt(0).toUpperCase() + props.type.substring(1)
    : "Normal";

  let listIcon: any = null;

  let items: any = [];
  if (props.type === "Custom") {
    React.Children.map(props.children, (child: any, index) => {
      if (child.type) {
        items.push(
          <div className={"lnf-custom-icon-li"}>
            {listIcon}
            {child}
          </div>
        );
      }
    });
  } else {
    items = props.children;
  }

  return (
    <div
      className={["lnf-list", "lnf-list-heading-" + props.topHeader].join(" ")}
      style={{
        ...style.normal,
      }}
    >
      {/*<style>{".lnf-list[uuid='"+id+"'] li::marker, .lnf-list[uuid='"+id+"'] li::before {color: "+props.iconColor+"}"}</style>*/}

      {props.type === "Numbered" ? (
        <ol className={"lnf-list-list " + type} style={style.list}>
          {items}
        </ol>
      ) : (
        <ul className={"lnf-list-list " + type} style={style.list}>
          {items}
        </ul>
      )}
    </div>
  );
};

export default ListComp;
