import {
  FC,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styles from "./Layout.module.scss";
import StoryblokContext from "../../store/StoryblokContext";
import CallToAction from "./CallToAction";
import CookieBanner from "./CookiesBanner";
import Header from "./Header";
import Meta from "./Meta";
import {
  StoryblokContextData,
  cleanContext,
  getStorySettings,
} from "../storyblok/StoryblokData";
import Footer from "./Footer";

interface LayoutProps {}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const Layout: FC<LayoutProps> = (props) => {
  const [editMode, setEditMode] = useState(false);
  const ctx: StoryblokContextData = useContext(StoryblokContext);
  const { story, info } = cleanContext(ctx);
  // console.log("Layout", { ctx, story, info });
  const { isSharedComponent, fullSlug, storySlug } = info;

  useLayoutEffect(() => {
    setEditMode(inIframe());
  }, []);

  // const router = useRouter();
  // console.log("Layout", {
  //   ctx,
  //   fullSlug,
  //   storySlug,
  //   isSharedComponent,
  // });
  if (story === false || story === undefined) {
    console.error("Layout: no story provided", { ctx });
    return (
      <main>
        <h1>StoryBlok page failed to load</h1>
      </main>
    );
  }
  if (isSharedComponent) {
    return <main data-editmode={editMode}>{props.children}</main>;
  }
  const {
    hideCallToAction = false,
    backgroundColour = "#fff",
    callToActionTheme,
  } = getStorySettings(story);

  return (
    <Fragment>
      <Meta story={story} />
      <style>{`body{background-color:${backgroundColour}`}</style>

      <Header />

      <main className={styles.main} data-editmode={editMode}>
        {props.children}
      </main>

      {hideCallToAction ? undefined : (
        <CallToAction theme={callToActionTheme} />
      )}

      <Footer />
      <CookieBanner />
    </Fragment>
  );
};

export default Layout;
