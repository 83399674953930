import Link from "next/link";
import { FC } from "react";
import { StoryblokComponent } from "storyblok-js-client";
import DynamicComponent from "../../../DynamicComponent";
import Icon from "../icon/Icon";
import BlokImageSlideShow from "../imageSlideShow/ImageSlideShow";
import Section from "../section/Section";
import styles from "./Project.module.scss";

interface Blok {
  title: string;
  headline: string;
  location: string;
  locationShort: string;
  client: string;
  duration: string;
  type: string;
  status: string;
  deliveryModel: string;
  sector: string;
  image: any;
  gallery: any;
  content: StoryblokComponent<string>[];
}

interface PageProps {
  blok: Blok;
}

const ProjectPage: FC<PageProps> = ({ blok }) => {
  const {
    title,
    headline,
    content,
    location,
    locationShort,
    client,
    duration,
    type,
    status,
    image,
    sector,
    deliveryModel,
    gallery,
  } = blok;
  return (
    <main className={styles.Project}>
      <div className={styles.ProjectInner}>
        <Link href={"/projects"} passHref>
          <a className={styles.BackLink}>
            <Icon name="arrowleft" />
            Back to all projects
          </a>
        </Link>
        <h2>{headline}</h2>
        <div className={styles.ProjectInfo}>
          <div>
            <h4>Client</h4>
            <span>{client}</span>
          </div>
          <div>
            <h4>Project Sector</h4>
            <span>{sector}</span>
          </div>

          <div>
            <h4>Duration</h4>
            <span>{duration}</span>
          </div>
          <div>
            <h4>Delivery Model</h4>
            <span>{deliveryModel}</span>
          </div>
        </div>
      </div>
      {gallery && gallery.length && (
        <div className={styles.SlideshowWrapper}>
          <BlokImageSlideShow
            blok={{
              items: (gallery || []).map((img: any) => {
                return {
                  image: img,
                  width: "100%",
                  height: "600px",
                  altText: img.altText,
                  fit: "cover",
                  position: "50% 50%",
                };
              }),
            }}
          />
        </div>
      )}
      <div className={styles.ProjectInner + " " + styles.ProjectContent}>
        {blok.content
          ? blok.content.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))
          : null}
      </div>
      <Section blok={LetsChatBlok} />
    </main>
  );
};

const LetsChatBlok: any = {
  _uid: "9a8b0edf-bdcf-4fd5-b099-4a521a43dd1b",
  body: [
    {
      gap: "60",
      _uid: "36c7bb41-8eb8-4003-ba9d-9c38196c13cc",
      wrap: "wrap",
      items: [
        {
          _uid: "033f510f-2441-4db3-bcbb-513d9cec7536",
          body: {
            type: "doc",
            content: [
              {
                type: "heading",
                attrs: {
                  level: 2,
                },
                content: [
                  {
                    text: "Have a project in mind? ",
                    type: "text",
                    marks: [
                      {
                        type: "bold",
                      },
                    ],
                  },
                  {
                    type: "hard_break",
                  },
                  {
                    text: "Let’s chat!",
                    type: "text",
                  },
                ],
              },
            ],
          },
          fontSize: "16",
          minWidth: "",
          component: "content",
          textAlign: "left",
          textColor: "",
        },
        {
          _uid: "38c79821-4b27-4b82-a77c-823503622fcc",
          body: {
            type: "doc",
            content: [
              {
                type: "blok",
                attrs: {
                  id: "bcb64beb-febe-43dc-985b-65537860acf8",
                  body: [
                    {
                      _uid: "i-e1e3f94a-a6ba-4062-98a8-daa27dc2bb44",
                      icon: "right",
                      link: {
                        id: "21a8c071-5914-4a90-9be6-5524753aac46",
                        url: "",
                        linktype: "story",
                        fieldtype: "multilink",
                        cached_url: "contact-us",
                      },
                      size: "small",
                      label: "Contact us",
                      iconSide: "right",
                      component: "button",
                      iconCustom: true,
                    },
                  ],
                },
              },
            ],
          },
          fontSize: "16",
          minWidth: "",
          component: "content",
          textAlign: "right",
          textColor: "",
        },
      ],
      columns: "2",
      overflow: false,
      component: "columns",
      textAlign: "left",
      columnsLarge: "2",
      columnsSmall: "1",
      verticalAlign: "center",
      horizontalAlign: "space-between",
      columnSizesCustom: "",
      columnsExtraSmall: "1",
      verticalAlignLarge: "",
      verticalAlignSmall: "",
      horizontalAlignLarge: "",
      horizontalAlignSmall: "",
      verticalAlignExtraSmall: "",
      horizontalAlignExtraSmall: "",
    },
  ],
  hide: false,
  anchor: "contact",
  padding: "150px 0",
  maxWidth: "1200px",
  overflow: false,
  component: "section",
  verticalAlign: "center",
  backgroundColor: "#E7EEA0",
  backgroundImage: {
    id: null,
    alt: null,
    name: "",
    focus: null,
    title: null,
    filename: "",
    copyright: null,
    fieldtype: "asset",
  },
  horizontalAlign: "left",
  invertTextColor: false,
  disableAnimation: false,
};

export default ProjectPage;
