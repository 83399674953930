import Link from "next/link";
import { FC, useEffect, useState } from "react";
import Icon from "../icon/Icon";

import styles from "./Projects.module.scss";
import Storyblok from "../../../../lib/storyblok";
import { Button } from "../button/Button";
import { valHooks } from "jquery";
import { useRouter } from "next/router";
import { duration } from "moment";
import { useMediaQuery } from "usehooks-ts";

interface Blok {}

interface PageProps {
  blok: Blok;
}
async function getProjects() {
  let { data, total } = await Storyblok.get(`cdn/stories`, {
    sort_by: "created_at:desc",
    content_type: "project",
    per_page: 100,
  });

  return { posts: data.stories, total };
}

async function getFilters() {
  let { data: types } = await Storyblok.get(
    `cdn/datasource_entries?datasource=project-type`,
    {}
  );
  // let { data: locations } = await Storyblok.get(
  //   `cdn/datasource_entries?datasource=project-location`,
  //   {}
  // );
  // let { data: status } = await Storyblok.get(
  //   `cdn/datasource_entries?datasource=project-status`,
  //   {}
  // );

  return {
    filters: {
      types: types.datasource_entries,
      // locations: locations.datasource_entries,
      // status: status.datasource_entries,
    },
  };
}
const Projects: FC<PageProps> = ({ blok }) => {
  const { push, query } = useRouter();
  const small = useMediaQuery("(max-width: 640px)");

  const [loaded, setLoaded] = useState(0);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filterOptions, setOptions] = useState<any>({});
  const [type, setType] = useState("*");
  // const [location, setLocation] = useState("*");
  // const [status, setStatus] = useState("*");
  // const hasFilter = type !== "*" || location !== "*" || status !== "*";
  const hasFilter = type && type !== "*";

  useEffect(() => {
    const queryType: any = query.type;
    const queryLoc: any = query.location;
    const queryStatus: any = query.status;
    if (!queryType) {
      setType("*");
    }
    // if (!queryLoc) {
    //   setLocation("*");
    // }
    // if (!queryStatus) {
    //   setStatus("*");
    // }
    // console.log({ query });

    getProjects().then((data) => {
      setProjects(data.posts);
      setLoaded((loaded) => loaded + 1);
    });

    getFilters().then((data: any) => {
      setOptions(data.filters);
      setLoaded((loaded) => loaded + 1);
    });
  }, []);

  useEffect(() => {
    if (loaded <= 0) return;
    const filtered = [...projects].filter((proj: any) => {
      if (type && type !== "*" && type !== proj.content.type) return false;
      // if (
      //   location &&
      //   location !== "*" &&
      //   location !== proj.content.locationShort
      // )
      //   return false;
      // if (status && status !== "*" && status !== proj.content.status)
      //   return false;
      return true;
    });
    setFilteredProjects(filtered);
    // }, [type, location, status, projects, loaded]);
  }, [type, projects, loaded]);

  useEffect(() => {
    const queryVal: any = query.type;
    setType(queryVal);
  }, [query.type]);

  // useEffect(() => {
  //   const queryVal: any = query.location;
  //   setLocation(queryVal);
  // }, [query.location]);

  // useEffect(() => {
  //   const queryVal: any = query.status;
  //   setStatus(queryVal);
  // }, [query.status]);

  // console.log("projects", { loaded, projects });

  return (
    <main className={styles.Projects}>
      <div className={styles.ProjectFilters}>
        <div>
          <Filter
            title={"Project Type"}
            defaultVal={type}
            options={filterOptions["types"] || []}
            onChange={(option: any) => {
              push({ query: { ...query, type: option } }, undefined, {
                shallow: true,
              });
            }}
          />
        </div>
        {/* <div>
          <Filter
            title={"Location (Suburb)"}
            defaultVal={location}
            options={filterOptions["locations"] || []}
            onChange={(option: any) => {
              push({ query: { ...query, location: option } }, undefined, {
                shallow: true,
              });
            }}
          />
        </div>
        <div>
          <Filter
            title={"Project Status"}
            defaultVal={status}
            options={filterOptions["status"] || []}
            onChange={(option: any) => {
              push({ query: { ...query, status: option } }, undefined, {
                shallow: true,
              });
            }}
          />
        </div> */}
        {hasFilter && !small && (
          <Button
            className={styles.clearButton}
            label={"Clear filters"}
            iconLeft={"close"}
            // iconSide={"right"}
            onClick={() => {
              push(
                { query: { ...query, type: "*", location: "*", status: "*" } },
                undefined,
                {
                  shallow: true,
                }
              );
            }}
          />
        )}
      </div>

      <div className={styles.ProjectItems}>
        {filteredProjects.map((project: any, index: number) => {
          return (
            <div key={`project_${index}`}>
              <Project
                project={{
                  title: project.content?.title,
                  location: project.content?.location,
                  client: project.content?.client,
                  slug: project.slug,
                  image: project.content?.image,
                  sector: project.content?.sector || project.content?.type,
                  duration: project.content?.duration,
                }}
              />
            </div>
          );
        })}
        {filteredProjects.length === 0 && (
          <div>
            <h2>No results found.</h2>
          </div>
        )}
      </div>
    </main>
  );
};

const Filter: FC<any> = ({ options, title, onChange, defaultVal }) => {
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState(defaultVal);
  const small = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    setVal(defaultVal);
  }, [defaultVal]);

  useEffect(() => {
    const resizer = () => {
      if (small) {
        document
          .getElementById("projectsArea")
          ?.style.setProperty("z-index", open ? "10000" : "1");
      }
    };
    resizer();
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  }, [open, small]);

  return (
    <main className={styles.Filter} data-open={open}>
      <div
        className={styles.Label}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span>{val === "*" || !val || open ? `Filter by ${title}` : val}</span>
        <Icon
          className={styles.indicator}
          name={small && open ? "close" : small ? "filter" : "arrowright"}
        />
      </div>
      <div className={styles.Dropdown}>
        <div
          className={styles.Option}
          onClick={() => {
            setVal("*");
            setOpen(false);
            onChange("*");
          }}
        >
          All
        </div>
        {options.map((option: any, index: number) => {
          return (
            <div
              key={`opt_${index}`}
              className={styles.Option}
              data-active={val == option.name}
              onClick={() => {
                setVal(option.name);
                if (!small) {
                  setOpen(false);
                  onChange(option.name);
                }
              }}
            >
              {option.name}
              {val == option.name && (
                <Icon className={styles.check} name={"check2"} />
              )}
            </div>
          );
        })}
      </div>
      {open && small && (
        <div className={styles.buttons}>
          <Button
            className={styles.applyButton}
            label={"Apply filters"}
            onClick={() => {
              setOpen(false);
              onChange(val);
            }}
          />
          <Button
            className={styles.clearButton}
            label={"Clear filters"}
            iconRight={"close"}
            onClick={() => {
              setVal("*");
              setOpen(false);
              onChange("*");
            }}
          />
        </div>
      )}
    </main>
  );
};

const Project: FC<any> = ({ project }) => {
  const { title, location, client, image, slug, sector, duration } = project;
  return (
    <main className={styles.Project}>
      <div
        className={styles.Background}
        data-image={!!image.filename}
        style={{
          backgroundImage: `url('${
            image.filename
          }${"/m/400x300/filters:fill(transparent):format(jpg)"}')`,
        }}
      ></div>
      <div className={styles.Content}>{title}</div>
      <div className={styles.Content}>
        <div>{sector}</div> <div>{duration}</div>
      </div>
      <Link href={`/projects/${slug}`}>
        <a className={styles.bigClicker}></a>
      </Link>
    </main>
  );
};

export default Projects;
