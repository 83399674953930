import { FC, useEffect, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import Icon from "../icon/Icon";
import styles from "./Button.module.scss";
import $ from "jquery";
import { URLfromLink } from "../footerBlok/FooterBlok";
import Link from "next/link";
import { emptyString } from "../content/Content";
import { AnyNaptrRecord } from "dns";
import { ShareButton } from "../dropdown/Dropdown";
import useMediaQuery from "./useMediaQuery";

interface Schema {
  label?: string;
  link?: any;
  url?: any;

  active?: boolean;
  disabled?: boolean;

  filled?: boolean;
  bordered?: boolean;
  shade?: boolean;
  circle?: boolean;
  noText?: boolean;
  underline?: boolean;
  noOutline?: boolean;
  thickOutline?: boolean;

  special?: "share";

  fullWidthMobile?: boolean;
  iconLeft?: string;
  iconRight?: string;
  iconColour?: string;
  backgroundTheme?: string;

  style?: string;
  size?: "small" | "medium" | "large";
  onClick?: (e: any) => void;
  className?: string;
  editable?: any;

  presetName?: string;
  preset?: ButtonPreset;
}
type ButtonStateOptions =
  | "underline"
  | "filled"
  | "bordered"
  | "shade"
  | "circle"
  | "thickOutline"
  | "noText"
  | "noOutline";

type ButtonState = {
  [key in ButtonStateOptions]?: boolean;
};

interface ButtonPreset {
  normal?: ButtonState;
  hover?: ButtonState;
  focus?: ButtonState;
  active?: {
    normal?: ButtonState;
    hover?: ButtonState;
    focus?: ButtonState;
  };
}

interface Props {
  blok: Schema;
}

export const ButtonPresets: {
  [key: string]: ButtonPreset;
} = {
  primary: {
    normal: {
      filled: true,
    },
    hover: {
      shade: true,
    },
    focus: {
      bordered: true,
      shade: false,
    },
  },
  secondary: {
    normal: {},
    hover: {
      thickOutline: true,
    },
    focus: {
      thickOutline: true,
    },
  },
  tertiary: {
    normal: {
      noOutline: true,
      underline: true,
    },
    hover: {
      noOutline: false,
    },
    focus: {
      thickOutline: true,
    },
  },
  pagination: {
    normal: {
      noOutline: true,
      circle: true,
      underline: true,
    },
    hover: {
      noOutline: false,
      underline: false,
    },
    focus: {
      noOutline: false,
      bordered: true,
      underline: true,
    },
    active: {
      normal: {
        noOutline: false,
        filled: true,
        bordered: false,
        underline: false,
        circle: true,
      },
    },
  },
  dropdown: {
    active: {
      normal: {
        thickOutline: true,
      },
    },
  },
  backLink: {
    normal: {
      noOutline: true,
    },
    hover: {
      noOutline: true,
    },
  },
  toggleButton: {
    focus: {
      thickOutline: true,
    },
    hover: {
      thickOutline: true,
    },
    active: {
      normal: {
        filled: true,
      },
      hover: {
        shade: true,
      },
      focus: {
        bordered: true,
      },
    },
  },
};

export const BlokButton: FC<Props> = ({ blok }) => {
  const url = URLfromLink(blok.link);
  // console.log({ blok, url, link: blok.link });
  const props = {
    editable: sbEditable(blok),
    ...blok,
    url,
    preset: blok.preset
      ? blok.preset
      : blok.presetName
      ? ButtonPresets[blok.presetName]
      : undefined,
  };

  if (blok?.special === "share") {
    return <ShareButton />;
  }

  return <Button {...props} />;
};

export const Button: FC<Schema> = ({
  label,
  url,
  filled,
  className,
  disabled,
  bordered,
  active: propActive,
  shade,
  circle,
  thickOutline,
  fullWidthMobile,
  underline,
  noOutline,
  iconLeft,
  iconRight,
  iconColour,
  backgroundTheme,
  style,
  size,
  editable,
  noText,
  onClick,
  preset,
}) => {
  const mobile = useMediaQuery("(max-width: 1000px)");
  const [isMobile, setMobile] = useState<boolean>(mobile);
  const [active, setActive] = useState<boolean>(propActive || false);
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setActive(propActive || false);
  }, [propActive]);

  useEffect(() => {
    setMobile(mobile);
  }, [mobile]);
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }, []);

  let iconLeftD =
    !emptyString(iconLeft) && iconLeft ? (
      <Icon className={"buttonIcon"} colour={iconColour} name={iconLeft} />
    ) : undefined;
  let iconRightD =
    !emptyString(iconRight) && iconRight ? (
      <Icon className={"buttonIcon"} colour={iconColour} name={iconRight} />
    ) : undefined;

  const withLeftIcon = iconLeftD !== undefined;
  const withRightIcon = iconRightD !== undefined;

  const buttonProperties: any = doButtonProperties(
    {
      filled,
      disabled,
      noOutline,
      underline,
      bordered,
      shade,
      thickOutline,
      noText,
      circle,
      expandMobile: fullWidthMobile,
      leftIconed: withLeftIcon,
      rightIconed: withRightIcon,
      size,
      style,
    },
    preset,
    hover,
    focus,
    active
  );

  const handleClick = (e: any) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    onClick && onClick(e);

    if (url && url.indexOf("scroll:") === 0) {
      const anchorEl = url.replaceAll("scroll:", "");
      const ele: any = $(`#${anchorEl}`);
      $(document).animate(
        {
          scrollTop: ele.offset().top + 40,
        },
        3000
      );
    }
  };

  let button = (
    <button
      {...editable}
      data-active={active}
      data-focus={focus}
      data-hover={hover}
      data-mobile={isMobile}
      className={["themedButton", className].join(" ")}
      onClick={handleClick}
      disabled={disabled}
      data-style={propsToTags(buttonProperties)}
      title={label}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      data-link={url !== undefined}
      data-url={url}
    >
      <div className="buttonInner">
        {iconLeftD}
        {label && <span>{label}</span>}
        {iconRightD}
      </div>
    </button>
  );
  if (url) {
    button = <Link href={disabled ? "#" : url}>{button}</Link>;
  }
  // if (backgroundTheme) {
  button = (
    <div
      className={backgroundTheme + ` ${className}`}
      data-theme={backgroundTheme}
      style={{
        background: "transparent",
        width: fullWidthMobile && isMobile ? "100%" : "fit-content",
        display: "inline-block",
      }}
    >
      {button}
    </div>
  );
  // }

  return button;
};

export function propsToTags(props: any) {
  const items = [];
  for (const key in props) {
    const val = props[key];
    if (val !== undefined) {
      if (typeof val === "boolean") {
        val && items.push(key);
      } else if (typeof val === "string") {
        items.push(val);
      } else {
        // console.log("propsToTags unknown", { key, val });
      }
    }
  }
  return items.join(", ");
}

function doButtonProperties(
  properties: any,
  preset: ButtonPreset | undefined,
  hover: boolean,
  focus: boolean,
  active: boolean
) {
  if (!preset) return properties;

  if (active && preset.active) {
    properties = fromState(properties, preset.active?.normal, true);
    properties = fromState(properties, preset.active?.hover, hover);
    properties = fromState(properties, preset.active?.focus, focus);
  } else {
    properties = fromState(properties, preset.normal, true);
    properties = fromState(properties, preset.hover, hover);
    properties = fromState(properties, preset.focus, focus);
  }

  return properties;
}

function fromState(
  buttonProperties: any,
  presetState?: ButtonState,
  active?: boolean
) {
  if (!active) return buttonProperties;
  if (presetState !== undefined) {
    for (const key in presetState as ButtonState) {
      const val = presetState[key as ButtonStateOptions];
      if (val !== undefined) {
        buttonProperties[key] = val;
      }
    }
  }
  return buttonProperties;
}
export default BlokButton;
