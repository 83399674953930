import { FC, useState } from "react";
import styles from "./IconCard.module.scss";
import Link from "next/link";
import Icon from "../icon/Icon";
import { sbEditable } from "@storyblok/storyblok-editable";

interface Schema {
  icon: string;
  title: string;
  link: any;
  description: string;
}

interface Props {
  blok: Schema;
}

const BlokIconCard: FC<Props> = ({ blok }) => {
  let url = undefined;
  if (blok.link) {
    if (blok.link.linktype === "story" && blok.link.cached_url.length > 0) {
      url = "/" + blok.link.cached_url;
    } else if (blok.link.url.length > 0) {
      url = blok.link.url;
    }
  }

  return (
    <div className={"IconCard"} {...sbEditable(blok)}>
      <Icon className={"icon"} name={blok.icon} />
      <h3 className={"title"}>
        <span>{blok.title}</span>
      </h3>
      {blok.description && <p className={"excerpt"}>{blok.description}</p>}

      {url && (
        <Link href={url} passHref>
          <a className={"bigClicker"} title={blok.title}></a>
        </Link>
      )}
    </div>
  );
};

export default BlokIconCard;
