import { FC, useEffect, useRef, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./SwipeCards.module.scss";
import Icon from "../icon/Icon";
import { useSwipeable } from "react-swipeable";

interface Schema {
  items: any;
}

interface Props {
  blok: Schema;
}

const BlokSwipeCards: FC<Props> = ({ blok }) => {
  const [slides, setSlides] = useState([]);
  const [animate, setAnimate] = useState("none");
  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Left") {
        goRight();
        eventData.event.preventDefault();
        return false;
      }
      if (eventData.dir === "Right") {
        goLeft();
        eventData.event.preventDefault();
        return false;
      }
    },
  });

  useEffect(() => {
    const tempSlides: any = [];
    blok.items.map((card: any, index: number) => {
      card.position = index;
      tempSlides.push(card);
    });
    if (tempSlides.length > 2) {
      tempSlides.push(...tempSlides);
    }
    setSlides(tempSlides);
  }, []);

  const goLeft = () => {
    if (animate !== "none") return;

    const newSlides = arrayRotate([...slides], true);

    setAnimate("left");
    setTimeout(() => {
      setSlides(newSlides);
      setAnimate("none");
    }, 1000);
  };

  const goRight = () => {
    if (animate !== "none") return;
    const newSlides = arrayRotate([...slides], false);
    setAnimate("right");
    setTimeout(() => {
      setSlides(newSlides);
      setAnimate("none");
    }, 1000);
  };

  const lastSlideIndex: any = slides.length - 1;
  const lastSlide: any = slides[lastSlideIndex];

  return (
    <div className={styles.SwipeCards} data-debug={false} {...handlers}>
      <div className={styles.slides} data-animate={animate}>
        {lastSlide && (
          <div
            className={styles.slideWrapper}
            key={`slide_last`}
            data-index={"last"}
            data-last={true}
          >
            <div className={styles.slide}>
              <p className={styles.message}>{lastSlide.message}</p>
              <div className={styles.info}>
                <span className={styles.author}>{lastSlide.author}</span>
                <span className={styles.from}>{lastSlide.from}</span>
              </div>
            </div>
          </div>
        )}
        {slides.map((slide: any, index: number) => {
          if (index > 3 && index != lastSlideIndex) return;

          return (
            <div
              className={styles.slideWrapper}
              key={`slide_${index}`}
              data-index={index}
              data-last={index == slides.length - 1}
            >
              <div className={styles.slide}>
                <p className={styles.message}>{slide.message}</p>
                <div className={styles.info}>
                  <span className={styles.author}>{slide.author}</span>
                  <span className={styles.from}>{slide.from}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.actions}>
        <div
          className={styles.left}
          onClick={() => goLeft()}
          title={"Scroll Left"}
          data-disabled={animate !== "none"}
        >
          <Icon name={"chevronleft"} />
        </div>
        <div
          className={styles.right}
          onClick={() => goRight()}
          title={"Scroll Right"}
          data-disabled={animate !== "none"}
        >
          <Icon name={"chevronright"} />
        </div>
      </div>
    </div>
  );
};

export default BlokSwipeCards;

function arrayRotate(arr: any, reverse: boolean) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
}
