import { FC, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./Image.module.scss";
import Link from "next/link";
import { fixMessurement } from "../Spacer/Spacer";
import { emptyString } from "../content/Content";
import { useMediaQuery } from "usehooks-ts";

interface Schema {
  image: any;
  aspectRatio: string;
  label: string;
  link: any;
  width: string;
  widthMobile: string;

  height: string;
  heightMobile: string;
  altText: string;
  fit: string;
  position: string;
  filterIn: boolean;
  borderRadius: string;
  horizontalAlign: string;
}

interface Props {
  blok: Schema;
}

const BlokImage: FC<Props> = ({ blok }) => {
  const mobile = useMediaQuery("(max-width: 1000px)");
  const [hover, setHover] = useState(false);
  let url = undefined;
  if (blok.link) {
    if (blok.link.linktype === "story" && blok.link.cached_url.length > 0) {
      url = "/" + blok.link.cached_url;
    } else if (blok.link.url.length > 0) {
      url = blok.link.url;
    }
  }

  const width = mobile ? blok.widthMobile || blok.width : blok.width;
  const height = mobile ? blok.heightMobile || blok.height : blok.height;
  const style: any = {
    link: {
      maxWidth: "100%",
      width: !emptyString(blok.horizontalAlign) ? "fit-content" : "100%",
      // width: "fit-content",
      height: blok.aspectRatio ? "fit-content" : height,
      display: "inline-flex",
      justifyContent: !emptyString(blok.horizontalAlign)
        ? blok.horizontalAlign
        : "center",
    },
    normal: {
      maxWidth: "100%",
      width: width,
      aspectRatio: blok.aspectRatio,
      height: blok.aspectRatio ? "fit-content" : height,
      objectFit: blok.fit,
      objectPosition: blok.position,
      borderRadius: fixMessurement(blok.borderRadius || "0px"),
      ...(blok.filterIn
        ? {
            filter: `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")`,
            WebkitFilter: "grayscale(100%)",
            transition: "all .2s ease-in-out",
          }
        : null),
    },
    hover: {
      ...(blok.filterIn
        ? {
            filter: "none",
            WebkitFilter: "grayscale(0%)",
          }
        : null),
    },
  };

  const imageOb = (
    <>
      <img
        width={blok.width}
        height={blok.height}
        className={styles.image}
        alt={blok.altText || "Image"}
        style={
          {
            ...style.normal,
            ...(hover ? style.hover : null),
          } as any
        }
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        {...sbEditable(blok)}
        src={blok.image.filename}
      />
      {blok.label && <div className={styles.label}>{blok.label}</div>}
    </>
  );

  return url ? (
    <Link href={url} passHref>
      <a title={blok.altText} style={style.link}>
        {imageOb}
      </a>
    </Link>
  ) : (
    <div style={style.link}>{imageOb}</div>
  );
};

export default BlokImage;
