import { FC, useContext, useEffect, useState } from "react";
import Storyblok from "../../../../lib/storyblok";
import StoryblokContext from "../../../../store/StoryblokContext";
import BlogCard from "../blogCard/BlogCard";
import styles from "./SimilarBlogs.module.scss";
interface Blok {}

interface PageProps {
  blok: Blok;
}

async function getSimilarPosts(story: any) {
  let { data } = await Storyblok.get(`cdn/stories`, {
    sort_by: "sort_by_date:desc",
    // by_slugs: "blog/*",
    content_type: "blogPost",
    with_tag: story.tag_list,
    per_page: 100,
  });

  const filteredPosts = data.stories.filter((storyb: any) => {
    return storyb.slug !== story.slug;
  });
  return filteredPosts;
}

const SimilarBlogs: FC<PageProps> = ({ blok }) => {
  const ctx = useContext(StoryblokContext);
  const [similarBlogs, setSimilarBlogs] = useState([]);
  const currentBlog = ctx.story;

  useEffect(() => {
    getSimilarPosts(currentBlog).then((data) => {
      if (data.length > 3) data.length = 3;

      setSimilarBlogs(data);
    });
  }, [currentBlog]);

  return similarBlogs && similarBlogs.length > 0 ? (
    <div className={styles.SimilarBlogs}>
      <h5 className={styles.title}>Similar Blog Posts</h5>
      <div className={styles.blogs}>
        {similarBlogs?.map((blog, index) => {
          return (
            <div key={`similarBlog_${index}`}>
              {/* <BlogCard blok={{ blog: blog }} /> */}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SimilarBlogs;
