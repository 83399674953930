import { FC, useCallback, useContext, useEffect, useState } from "react";
import StoryblokContext from "../../store/StoryblokContext";
import DynamicComponent from "../DynamicComponent";
import { getStorySettings } from "../storyblok/StoryblokData";

const CallToAction: FC<{ theme: string }> = ({ theme }) => {
  const ctx = useContext(StoryblokContext);
  if (!ctx?.shared?.stories?.calltoaction) return null;
  const { content = [] } = getStorySettings(ctx?.shared?.stories?.calltoaction);

  return (
    <section className={"CallToAction"} data-theme={theme || "black"}>
      {content.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </section>
  );
};

export default CallToAction;
