import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { randomUUID } from "crypto";

interface Schema {
  id: string;
  style: string;
  url: string;
}

interface Props {
  blok: Schema;
}

const BlokIframe: FC<Props> = ({ blok }) => {
  const id = blok.id || randomUUID();
  return (
    <div {...sbEditable(blok)}>
      <style>
        {`
      #${id}{
        ${blok.style}
        border: none;
      }
      `}
      </style>
      <iframe id={id} width={"100%"} height={"100%"} src={blok.url} />
    </div>
  );
};

export default BlokIframe;
