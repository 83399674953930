import { Children, FC, useEffect, useState } from "react";
import DynamicComponent from "../../../DynamicComponent";
import styles from "./Accordion.module.scss";
import Icon from "../icon/Icon";
import { useMediaQuery } from "usehooks-ts";

interface Schema {
  items: any;
  style: "normal" | "sidebar" | "tabs" | "numbered";
}

interface Props {
  blok: Schema;
}

const BlokAccordion: FC<Props> = ({ blok }) => {
  const { items, style } = blok;
  const [selectedIndex, setSelected] = useState(-1);
  const isNormal = style === "normal";
  const isNumbered = style === "numbered";
  const isTabs = style === "tabs";
  const small = useMediaQuery("(max-width: 1000px)");

  if (isNormal || small || isNumbered) {
    return (
      <div className={styles.accordion} data-style={style} data-stock>
        <div className={styles.accordionTabs}>
          {items &&
            items.map((item: any, index: number) => {
              return (
                <div
                  className={styles.accordionTabNormal}
                  data-active={index === selectedIndex}
                  key={`accordTab_${index}`}
                >
                  <div
                    // href={`#accordTab_${index}_content`}
                    className={styles.accordionTab}
                    data-active={index === selectedIndex}
                    id={`accordTab_${index}`}
                    onClick={() => {
                      if (selectedIndex === index) {
                        setSelected(-1);
                      } else {
                        setSelected(index);
                      }
                      // setTimeout(() => {
                      //   const el = document.getElementById(
                      //     `accordTab_${selectedIndex}_content`
                      //   ) as HTMLElement;
                      //   const elTitle = document.getElementById(
                      //     `accordTab_${selectedIndex}`
                      //   ) as HTMLElement;
                      //   elTitle.scrollIntoView({
                      //     behavior: "smooth",
                      //     block: "start",
                      //   });
                      // }, 100);
                    }}
                  >
                    <span>
                      {isNumbered ? index + 1 + ". " : undefined}
                      {item.title}
                    </span>
                    <Icon
                      name={index === selectedIndex ? "Remove" : "Add"}
                      className={styles.icon}
                    />
                  </div>
                  <div
                    className={styles.accordionContentWrapper}
                    data-active={selectedIndex === index}
                  >
                    <div
                      id={`accordTab_${index}_content`}
                      data-active={selectedIndex === index}
                      className={styles.accordionContent}
                      key={`accordTab_${index}`}
                    >
                      {item
                        ? item.content &&
                          item.content.map((childBlok: any, index: number) => (
                            <DynamicComponent blok={childBlok} key={index} />
                          ))
                        : undefined}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.accordion} data-style={style}>
      <div className={styles.accordionTabs}>
        {items &&
          items.map((item: any, index: number) => {
            return (
              <div
                key={`accordTab_${index}`}
                className={styles.accordionTab}
                data-active={index === selectedIndex}
                onClick={() => {
                  selectedIndex === index
                    ? setSelected(-1)
                    : setSelected(index);
                }}
              >
                {item.title}
              </div>
            );
          })}
        {isTabs && (
          <div
            className={styles.accordionTabIndicator}
            style={{
              translate: selectedIndex * 100 + "%",
              width: `calc(100% / ${items.length})`,
            }}
          ></div>
        )}
      </div>
      <div
        className={styles.accordionContentWrapper}
        style={{
          translate: isTabs ? -selectedIndex * 100 + "%" : undefined,
        }}
      >
        {items &&
          items.map((item: any, index: number) => {
            return (
              <div
                data-active={selectedIndex === index}
                className={styles.accordionContent}
                key={`accordTab_${index}`}
              >
                {item
                  ? item.content &&
                    item.content.map((childBlok: any, index: number) => (
                      <DynamicComponent blok={childBlok} key={index} />
                    ))
                  : undefined}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BlokAccordion;
