import { FC } from "react";
import { StoryblokComponent } from "storyblok-js-client";
import ComponentMap from "./storyblok";

export interface Blok extends StoryblokComponent<string> {
  body?: DynamicComponentParams[];
}

export interface DynamicComponentParams {
  blok: StoryblokComponent<string>;
}

const DynamicComponent: FC<DynamicComponentParams> = ({ blok }) => {
  // check if component is defined above
  const Component = ComponentMap[blok.component];
  if (typeof Component !== "undefined") {
    return <Component blok={blok} key={blok._uid} />;
  }

  // fallback if the component doesn't exist
  console.error("Blok not found", { blok });
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
};

export default DynamicComponent;
