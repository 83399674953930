import React, { useState, useRef } from "react";
import Icon from "../../../icon/Icon";
/**
 * Primary UI component for user interaction
 */
export const InputSelect = (props) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [filled, setFilled] = useState(false);
  const [selected, setSelected] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const input = useRef(null);

  const style = {
    normal: {
      backgroundColor: props.backgroundColor,
      borderRadius: props.borderRadius,
      color: props.textColor,
      fontFamily: props.fontFamily,
      fontWeight: props.fontWeight,
      fontSize: props.fontSize,
      // padding: props.padding,
    },
    hover: {},
    focus: {},
  };
  function updateInputValue(e) {
    e.target.value.length > 0 ? setFilled(true) : setFilled(false);
  }

  let options = [];
  let dropdownOptions = [];
  props.options.map((option, index) => {
    options.push(
      <option value={option.key} selected={selected === option.key}>
        {option.label}
      </option>
    );
    dropdownOptions.push(
      <div
        className={"lnf-select-dropdown-option"}
        data-selected={selected === option.key}
        onClick={() => {
          setSelected(option.key);
          setFilled(true);
          setDropdown(false);
        }}
        value={option.key}
      >
        {option.label}
      </div>
    );
  });

  function getLabel(selected) {
    let found = selected;
    props.options.map((option) => {
      if (option.key === selected) found = option.label;
    });
    return found;
  }

  const failed = props.failed && !filled ? props.failed.failed : false;

  let inputActions = props.disabled
    ? null
    : {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onFocus: () => setFocus(true),
        onBlur: () => {
          setFocus(false);
          setDropdown(false);
        },
        onClick: () => {
          setDropdown(!dropdown);
        },
      };

  let chevIcon = <></>;
  if (props.iconCustom) {
    chevIcon = (
      <Icon
        custom={props.iconCustom}
        icon={props.icon}
        color={props.disabled ? "#A3A3A3" : "#0a0a0a"}
      />
    );
  } else {
    chevIcon = (
      <Icon
        icon={"chevron-down"}
        color={props.disabled ? "#A3A3A3" : "#0a0a0a"}
      />
    );
  }

  return (
    <div
      className={[
        "lnf-input-select lnf-input",
        selected !== "" ? "filled" : null,
        failed ? "failed" : null,
        focus ? "focus" : null,
        hover ? "hover" : null,
      ].join(" ")}
      {...inputActions}
      tabIndex={0}
      data-disabled={props.disabled}
      style={{
        ...style.normal,
        ...(hover ? style.hover : null),
        ...(focus ? style.focus : null),
      }}
    >
      {chevIcon}
      <div className={"lnf-select-dropdown"} data-visible={dropdown}>
        {dropdownOptions}
      </div>

      <select
        className="lnf-input-select-input"
        onLoad={(e) => updateInputValue(e)}
        ref={input}
        name={props.slug}
        required={props.required}
        disabled={props.disabled}
        data-field={props.slug}
      >
        <option value={""} selected={selected === ""} disabled={"true"}>
          Select
        </option>
        {options}
      </select>
      <span className="lnf-input-placeholder">
        {" "}
        <b>{props.label}</b>
        {props.required ? " (required)" : ""}
      </span>
      <span className="lnf-input-selected">{getLabel(selected)}</span>
      {failed ? (
        <div className={"lnf-input-error"}>{props.failed.error}</div>
      ) : null}
    </div>
  );
};
