import { Children, FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../../DynamicComponent";
import { StoryblokComponent } from "storyblok-js-client";
import styles from "./Columns.module.scss";
import { fixMessurement } from "../Spacer/Spacer";
import { emptyString } from "../content/Content";

interface Schema {
  items: StoryblokComponent<any>[] | any[];
  columns?: number;
  columnsLarge?: number;
  columnsSmall?: number;
  columnsExtraSmall?: number;
  gap?: string;
  wrap?: string;
  verticalAlign?: string;
  horizontalAlign?: string;
  verticalAlignLarge?: string;
  horizontalAlignLarge?: string;
  verticalAlignSmall?: string;
  horizontalAlignSmall?: string;
  verticalAlignExtraSmall?: string;
  horizontalAlignExtraSmall?: string;
  textAlign?: string;
  textAlignLarge?: string;
  textAlignSmall?: string;
  textAlignExtraSmall?: string;
  columnSizesCustom?: any;
  overflow?: boolean;
  className?: string;
}

interface Props {
  blok: Schema;
}

const BlokColumns: FC<Props> = ({ blok }) => {
  let indexSizes: any = null;
  try {
    indexSizes = blok.columnSizesCustom
      ? JSON.parse(blok.columnSizesCustom)
      : null;
  } catch (e) {}
  const props = {
    ...blok,
    indexSizes,
    className: blok.className,
    columns: blok.columns,
    columnsLarge: blok.columnsLarge,
    columnsSmall: blok.columnsSmall,
    columnsExtraSmall: blok.columnsExtraSmall,
    align: blok.verticalAlign,
    justify: blok.horizontalAlign,
    alignLarge: blok.verticalAlignLarge
      ? blok.verticalAlignLarge
      : blok.verticalAlign,
    justifyLarge: blok.horizontalAlignLarge
      ? blok.horizontalAlignLarge
      : blok.horizontalAlign,
    textAlignLarge: blok.textAlignLarge ? blok.textAlignLarge : blok.textAlign,
    alignSmall: blok.verticalAlignSmall
      ? blok.verticalAlignSmall
      : blok.verticalAlign,
    justifySmall: blok.horizontalAlignSmall
      ? blok.horizontalAlignSmall
      : blok.horizontalAlign,
    textAlignSmall: blok.textAlignSmall ? blok.textAlignSmall : blok.textAlign,
    alignExtraSmall: blok.verticalAlignExtraSmall
      ? blok.verticalAlignExtraSmall
      : blok.verticalAlign,
    justifyExtraSmall: blok.horizontalAlignExtraSmall
      ? blok.horizontalAlignExtraSmall
      : blok.horizontalAlign,
    textAlignExtraSmall: blok.textAlignExtraSmall
      ? blok.textAlignExtraSmall
      : blok.textAlign,
    gap: blok.gap,
    wrap: blok.wrap,

    textAlign: blok.textAlign,
    overflow: blok.overflow,
  };

  return (
    <Columns {...props} editable={{ ...sbEditable(blok) }}>
      {blok.items.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </Columns>
  );
};

interface ComponentProps {
  className?: string;

  columns: number;
  columnsLarge?: number;
  columnsSmall?: number;
  columnsExtraSmall?: number;

  gap?: string;
  wrap?: string;

  justify?: string;
  justifyLarge?: string;
  justifySmall?: string;
  justifyExtraSmall?: string;

  align?: string;
  alignLarge?: string;
  alignSmall?: string;
  alignExtraSmall?: string;

  textAlign?: string;
  textAlignLarge?: string;
  textAlignSmall?: string;
  textAlignExtraSmall?: string;
  indexSizes: any;
  overflow: boolean;
  editable: any;
}

export const Columns: FC<ComponentProps | any> = (props) => {
  const {
    className,
    columns,
    columnsSmall,
    columnsLarge,
    columnsExtraSmall,
    gap,
    wrap,
    justify,
    justifyLarge,
    justifySmall,
    justifyExtraSmall,
    editable,
    align,
    alignLarge,
    alignSmall,
    alignExtraSmall,
    textAlign,
    textAlignSmall,
    textAlignLarge,
    textAlignExtraSmall,
    indexSizes,
    overflow,
    children,
  } = props;

  const fixedGap = fixMessurement(gap || "0");

  const style = {
    normal: {
      "--grid-gap": fixedGap,
      overflow: overflow ? "visible" : "hidden",
    },
    grid: {
      "--grid-gap": fixedGap,
      gap: fixedGap,
      overflow: overflow ? "visible" : "hidden",
      flexWrap: wrap,
    },
  };
  const columnClasses = [
    styles.Column,
    styles["align-" + align?.toLowerCase()],
    styles["align-large-" + alignLarge?.toLowerCase()],
    styles["align-small-" + alignSmall?.toLowerCase()],
    styles["align-extra-small-" + alignExtraSmall?.toLowerCase()],
  ].join(" ");

  let large = columnsLarge || columns;
  let small = !emptyString(columnsSmall?.toString()) ? columnsSmall : 1;
  let extraSmall = !emptyString(columnsExtraSmall?.toString())
    ? columnsExtraSmall
    : 1;

  let gridItems: any = [];

  Children.map(children, (child: any, index: number) => {
    if (child.type) {
      let width = 1;

      if (child.props.stretch) width = child.props.stretch;
      if (indexSizes && indexSizes[index] !== -1) width = indexSizes[index];

      const columnStyle = {
        "--grid-item-width": width,
        "--grid-gap": fixedGap,
        overflow: overflow ? "visible" : "hidden",
        width:
          columns === 0
            ? "fit-content"
            : `calc(100% / ${columns} * ${width} - ${fixedGap})`,
      };

      gridItems.push(
        <div
          className={columnClasses}
          style={columnStyle}
          // index={index}
          key={index}
          data-index={index}
          data-width={width}
          data-large={large}
          data-medium={columns}
          data-small={small}
          data-xsmall={extraSmall}
        >
          <div>{child}</div>
        </div>
      );
    }
  });

  return (
    <div
      {...editable}
      className={[
        styles.Columns,
        className,
        styles["Columns-large-" + large],
        styles["Columns-small-" + small],
        styles["Columns-extra-small-" + extraSmall],
      ].join(" ")}
      style={{
        ...style.normal,
      }}
    >
      <div
        className={[
          styles.ColumnsGrid,
          styles["justify-" + justify],
          styles["justify-large-" + justifyLarge?.toLowerCase()],
          styles["justify-small-" + justifySmall?.toLowerCase()],
          styles["justify-extra-small-" + justifyExtraSmall?.toLowerCase()],

          "text-" + textAlign,
          "text-large-" + textAlignLarge?.toLowerCase(),
          "text-small-" + textAlignSmall?.toLowerCase(),
          "text-extra-small-" + textAlignExtraSmall?.toLowerCase(),
        ].join(" ")}
      >
        {gridItems}
      </div>
    </div>
  );
};

export default BlokColumns;
