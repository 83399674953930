import { Children, FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../../DynamicComponent";
import { StoryblokComponent } from "storyblok-js-client";
import styles from "./Columns.module.scss";
import { emptyString } from "../content/Content";
import { cleanVars } from "../../../Theme";
interface Schema {
  bloks: StoryblokComponent<string>[];

  id: string;
  className: string;
  padding: string;
  margin: string;
  backgroundColor: string;
  theme: string;
  textAlign:
    | "center"
    | "end"
    | "justify"
    | "left"
    | "match-parent"
    | "right"
    | "start";
  overflow: boolean;
}

interface Props {
  blok: Schema;
}

const BlokContainer: FC<Props> = ({ blok }) => {
  const {
    bloks = [],
    id,
    className,
    textAlign,
    overflow,
    padding,
    backgroundColor,
    theme,
    margin,
  } = blok;

  return (
    <div
      id={id}
      className={`contentContainer ${className}`}
      {...sbEditable(blok)}
      data-theme={theme}
      style={{
        width: "100%",
        backgroundColor: !emptyString(backgroundColor)
          ? backgroundColor
          : undefined,
        padding: !emptyString(padding) ? cleanVars(padding) : undefined,
        margin: !emptyString(margin) ? cleanVars(margin) : undefined,
        overflow: !emptyString(overflow) && overflow ? "visible" : undefined,
        textAlign: !emptyString(textAlign) ? textAlign : undefined,
      }}
    >
      {bloks?.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </div>
  );
};

export default BlokContainer;
