import Link from "next/link";
import { FC, useContext, useEffect, useState } from "react";
import StoryblokContext from "../../store/StoryblokContext";
import Richtext from "../storyblok/components/content/RichText";
import Icon from "../storyblok/components/icon/Icon";
import styles from "./Header.module.scss";
import { URLfromLink } from "../storyblok/components/footerBlok/FooterBlok";
import $ from "jquery";
import { useRouter } from "next/router";
import { getStorySettings } from "../storyblok/StoryblokData";
import DynamicComponent from "../DynamicComponent";
import { BUSSINESS_NAME } from "../../project/project.info";

const Header: FC = () => {
  const ctx = useContext(StoryblokContext);

  const [refresh, setRefresh] = useState(0);
  const [sticky, setSticky] = useState(false);
  const [open, setOpen] = useState(false);
  const [ready, setReady] = useState(false);

  const hideIndent = true;

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 100);
    setRefresh(refresh + 1);

    const onScroll = () => {
      const level = $(window).scrollTop() || 0;
      setSticky(level > 0);
    };
    $(window).on("scroll", onScroll);
    onScroll();
    return () => {
      $(window).off("scroll", onScroll);
    };
  }, []);

  if (!ctx?.shared?.stories?.header) return null;
  const {
    maxWidth,
    backgroundImage,
    mobileContent,
    logo,
    mobileNavLogo = logo,
    navigation,
    contentRight,
  } = getStorySettings(ctx?.shared?.stories?.header);
  const { solidHeader, hideBackground } = getStorySettings(ctx?.story);

  return (
    <div
      className={styles.headerWrapper}
      data-indent={!hideIndent}
      data-sticky={sticky}
      data-hidebg={hideBackground}
      data-theme={"white"}
      data-ready={ready}
    >
      <header
        className={styles.header}
        data-open={open}
        data-sticky={sticky}
        data-hidebg={hideBackground}
        style={{
          backgroundColor: solidHeader ? "var(--header-sticky-bg)" : undefined,
          backgroundImage: backgroundImage
            ? !hideBackground
              ? `url('${backgroundImage.filename}')`
              : undefined
            : undefined,
        }}
      >
        <div
          className={styles.headerInner}
          style={{ maxWidth: maxWidth + "px" }}
        >
          <Link href="/" passHref>
            <a className={styles.logoLink}>
              <img
                className={styles.logo}
                title={"Go to the home page"}
                src={logo?.filename}
                alt={`${BUSSINESS_NAME} Logo`}
                width="110px"
                // width="auto"
                height="24px"
              />
            </a>
          </Link>

          <div className={styles.content}>
            <HeaderNavLinks navigation={navigation} />
          </div>
          <div className={styles.right}>
            {contentRight?.map((childBlok: any, index: number) => (
              <DynamicComponent blok={childBlok} key={index} />
            ))}
          </div>
        </div>
        <MobileNav
          mobileContent={mobileContent}
          mobileNavLogo={mobileNavLogo}
          content={contentRight}
          open={open}
          setOpen={setOpen}
          navigation={navigation}
        />
        <MobileNavButton setOpen={setOpen} open={open} />
      </header>
    </div>
  );
};

// function MobileNavButton({ setOpen, open }: any) {}

function HeaderNavLinks({ navigation }: any) {
  const router = useRouter();

  return (
    navigation && (
      <div className={styles.links}>
        {navigation.map((link: any, index: number) => {
          const url = URLfromLink(link.url);
          const active = isActive(url, router);

          return (
            <Link
              href={url}
              passHref
              key={index}
              title={link.title}
              scroll={true}
            >
              <a data-active={active}>{link.title}</a>
            </Link>
          );
        })}
      </div>
    )
  );
}

function MobileNavButton({ setOpen, open }: any) {
  return (
    <div
      className={styles.mobileNavButton}
      onClick={() => setOpen((wasOpen: boolean) => !wasOpen)}
    >
      <Icon name={open ? "Close" : "Menu"} />
    </div>
  );
}
function MobileNav({
  mobileContent,
  mobileNavLogo,
  navigation,
  open,
  setOpen,
  content,
}: any) {
  return (
    <div className={styles.mobileNav} data-open={open} data-theme={"black"}>
      <div className={styles.mobileNavTop}>
        <Link href="/" passHref>
          <a
            className={styles.mobileLogo}
            onClick={() => setOpen(false)}
            title={"Go to the home page"}
          >
            <img
              src={mobileNavLogo?.filename}
              alt={`${BUSSINESS_NAME} Logo`}
              width="110px"
              // width="auto"
              height="24px"
            />
          </a>
        </Link>
      </div>
      <div className={styles.mobileNavCenter}>
        <div className={styles.mobileLinks}>
          {navigation?.map((link: any, index: number) => {
            const url = URLfromLink(link.url);

            return (
              <Link href={url} passHref title={link.title} key={index}>
                <a onClick={() => setOpen(false)}>
                  <span>{link.title}</span>
                  <Icon name={"ChevronRight"} />
                </a>
              </Link>
            );
          })}
          {content?.map((childBlok: any, index: number) => (
            <DynamicComponent blok={childBlok} key={index} />
          ))}
        </div>
      </div>
      <div className={styles.mobileNavBottom}>
        <div className={styles.mobileContent}>
          <Richtext content={mobileContent} />
        </div>
      </div>
    </div>
  );
}

function isActive(link: string, router: any) {
  const { asPath } = router;

  if (link.endsWith("/")) {
    link = link.replace(/\/$/, ""); // remove trailing slash if it exists
  }
  const paths = [link, `${link}/`, `${link}/[id]`];

  return paths.includes(asPath);
}

export default Header;
