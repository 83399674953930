import React from "react";
import { FC } from "react";
import dynamic from "next/dynamic";

interface IconProps {
  name: string;
  colour?: string;
  className?: string;
  onClick?: () => void;
}

const DynamicIcon = dynamic(() => import("./DynamicIcon"), {
  ssr: false,
});

const Iconw: FC<IconProps> = (props) => {
  // const { icon: IconComponent, loading, error } = useDynamicIcon(props.name);
  // if (loading)
  //   return (
  //     <span data-icon={props.name} data-msg={"Icon Loading..."} hidden>
  //       I
  //     </span>
  //   );
  // if (error || !IconComponent)
  //   return (
  //     <span data-icon={props.name} data-msg={"Icon Not Found..."}>
  //       {props.name}
  //     </span>
  //   );
  return (
    <div
      className={props.className}
      onClick={props.onClick}
      data-icon={props.name}
      style={{
        display: "flex",
        color: props.colour || undefined,
      }}
    >
      <DynamicIcon iconName={props.name} />
    </div>
  );
};

export default Iconw;
