import React, { useState, useEffect } from "react";
import TextLoop from "react-text-loop";

interface Schema {
  prepend?: string;
  append?: string;
  text?: string;
  className?: string;
  interval?: string;
}

interface Props {
  blok: Schema;
}

const CycleText: React.FC<Props> = ({ blok }) => {
  const { text, interval = "2000", className, prepend, append } = blok;
  const texts = text ? text.split("\n") : [];
  const nInterval = [1, ...texts.map(() => Number(interval))];
  const all = [`<span style="color:transparent'">${texts[0]}</span>`, ...texts];
  return (
    <h1 className={className + " cycling-text-container"}>
      {prepend && <span className="prepend">{prepend}</span>}
      <TextLoop className="main" interval={nInterval}>
        {all.map((text, index) => (
          <span
            key={index}
            className="cycling-text"
            dangerouslySetInnerHTML={{
              __html: `${text},`,
            }}
          ></span>
        ))}
      </TextLoop>{" "}
      {append && <span className="append">{append}</span>}
    </h1>
  );
};

export default CycleText;
