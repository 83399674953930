import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import Storyblok, { Richtext } from "storyblok-js-client";
import {
  MARK_STYLED,
  render,
  NODE_HEADING,
} from "storyblok-rich-text-react-renderer";
import BlokButton from "../button/Button";
// import BlokList from "./List";
import BlokSpacer, { fixMessurement } from "../Spacer/Spacer";
import styles from "./Content.module.scss";
import moment from "moment";
import BlokImage from "../image/Image";
import BlokIconItems from "../iconItems/IconItems";
import BlokIcon from "../icon/BlokIcon";
import { type } from "os";
import CycleText from "../cycleText/CycleText";
import Container from "../container/Container";
import Newsletter from "../newsletter/Newsletter";
import Table from "../table/Table";

interface Schema {
  body: any;
  textAlignMobile?: string;
  textAlign?: string;
  textColor?: string;
  fontSize?: number;
  fontSizeMobile?: number;
  minWidth?: string;
  className?: string;
}

interface Props {
  blok: Schema;
}

export function slugify(string: string) {
  return string ? string.toString().toLowerCase().replaceAll(" ", "-") : "none";
}
export function emptyString(string?: any) {
  return (
    string === undefined ||
    string === null ||
    typeof string !== "string" ||
    string.trim().length === 0
  );
}
const Content: FC<Props> = ({ blok }) => {
  const align: any = blok.textAlign ? blok.textAlign : "left";

  const styleContent: any = {
    width: blok.minWidth ? blok.minWidth : "100%",
    maxWidth: "100%",
    margin: blok.minWidth ? "auto" : "0",
  };

  if (!emptyString(blok.fontSize)) {
    styleContent["--content-font-size"] = fixMessurement(blok.fontSize);
  }
  if (!emptyString(blok.fontSizeMobile)) {
    styleContent["--content-font-size-mobile"] = fixMessurement(
      blok.fontSizeMobile
    );
  } else {
    if (!emptyString(blok.fontSize)) {
      styleContent["--content-font-size-mobile"] = fixMessurement(
        blok.fontSize
      );
    }
  }

  !emptyString(blok.fontSize) &&
    (styleContent["--content-text-color"] = blok.textColor);

  return (
    <div
      className={
        styles.content +
        ` ${blok.className} text-${blok.textAlign} text-small-${blok.textAlignMobile}`
      }
      {...sbEditable(blok)}
      style={styleContent}
    >
      {render(blok.body, {
        blokResolvers: {
          ["icon"]: (props: any) => <BlokIcon blok={props} />,
          ["button"]: (props: any) => <BlokButton blok={props} />,
          ["iconItems"]: (props: any) => <BlokIconItems blok={props} />,
          ["spacer"]: (props: any) => <BlokSpacer blok={props} />,
          ["image"]: (props: any) => <BlokImage blok={props} />,
          ["content"]: (props: any) => <Content blok={props} />,
          ["cycleText"]: (props: any) => <CycleText blok={props} />,
          ["newsletter"]: (props: any) => <Newsletter blok={props} />,
          ["container"]: (props: any) => <Container blok={props} />,
          ["table"]: (props: any) => <Table blok={props} />,
          ["date"]: (props: any) => {
            return (
              <span>
                {props.date
                  ? moment(props.date).format(props.format)
                  : moment().format(props.format)}
              </span>
            );
            // return (<span>hello</span>)
          },
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, props) => {
            if (!children || (Array.isArray(children) && children.length === 0))
              return <></>;
            let object = <></>;
            let anchor = Array.isArray(children)
              ? slugify(
                  children[0].type ? children[0].props.children : children[0]
                )
              : "none";
            switch (props.level) {
              case 1:
                object = (
                  <h1
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h1>
                );
                break;
              case 2:
                object = (
                  <h2
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h2>
                );
                break;
              case 3:
                object = (
                  <h3
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h3>
                );
                break;
              case 4:
                object = (
                  <h4
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h4>
                );
                break;
              case 5:
                object = (
                  <h5
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h5>
                );
                break;
              case 6:
                object = (
                  <h6
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h6>
                );
                break;
            }
            return object;
          },
        },
        textResolver: (text) => {
          return text.replaceAll("{{year}}", moment().format("yyyy"));
        },

        // defaultStringResolver: (str) => <p style={{
        //     textAlign: align,
        //     color: blok.textColor+"!important",
        //     fontSize: blok.fontSize+"px!important"
        // }}>{str}</p>,
        // markResolvers: {
        //     [MARK_STYLED]: (children) => <p style={{
        //         textAlign: align,
        //         color: blok.textColor+"!important",
        //         fontSize: blok.fontSize+"px!important"
        //     }}>{children}</p>
        // }
      })}
    </div>
    // <div className="content" {...sbEditable(blok)} style={{
    //         width:"100%",textAlign: align
    //     }} dangerouslySetInnerHTML={{ __html: content }}/>
  );
};

export default Content;
