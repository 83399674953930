import { FC, useEffect, useRef, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../../DynamicComponent";
import { StoryblokComponent } from "storyblok-js-client";
import styles from "./Section.module.scss";
import { cleanColour, cleanVars } from "../../../Theme";
import { emptyString } from "../content/Content";
import { MultiQualityImage, SBImage } from "../image/useImage";
interface SectionSchema {
  anchor: string;
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "primaryGradient"
    | "secondaryGradient"
    | "white"
    | "black"
    | "carcoal"
    | "white-off";
  body: StoryblokComponent<string>[];
  // graphics: StoryblokComponent<string>[];
  backgroundColor: string;
  backgroundImagePosition: string;
  backgroundImageSize: string;
  backgroundImage: SBImage;
  maxWidth: string;
  padding: string;
  verticalAlign: string;
  horizontalAlign: string;
  invertTextColor: string;
  overflow: boolean;
  hide: boolean;
  disableAnimation: boolean;
  height: string;
}

interface SectionProps {
  blok: SectionSchema | any;
}

const BlokSection: FC<SectionProps> = ({ blok }) => {
  const [isVisible, setVisible] = useState(false);
  const [wasAbove, setAbove] = useState(false);
  const [hasAnimated, setAnimated] = useState(false); //only run once
  const domRef: any = useRef();
  const downOnly = true;
  const onlyOnce = true;

  function isInViewport(element: any) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  useEffect(() => {
    if (blok.hide) return;
    if (!blok.disableAnimation) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const isAbove =
            entry.boundingClientRect.y <
            (entry.rootBounds ? entry.rootBounds.y : 0);
          setAbove(isAbove);

          if (downOnly && isAbove) {
            setVisible(true);
            if (onlyOnce) {
              observer.unobserve(entry.target);
            }
            // setAnimated(true);
          } else {
            if (entry.isIntersecting) {
              if (onlyOnce) {
                observer.unobserve(entry.target);
              }
            }
            setVisible(entry.isIntersecting);
          }
        });
      });

      if (isInViewport(domRef.current)) {
        setAnimated(true);
        setVisible(true);
      } else {
        observer.observe(domRef.current);
      }
    } else {
      setVisible(true);
    }

    // return () => observer.unobserve(domRef.current);
  }, []);
  const props: any = {
    ...blok,
    theme: blok.theme,
    backgroundPosition: blok.backgroundImagePosition,
    backgroundSize: blok.backgroundImageSize,
    backgroundColor: blok.backgroundColor,
    backgroundImage: blok.backgroundImage ? blok.backgroundImage : undefined,
    containerMaxWidth: blok.maxWidth,
    containerJustify: blok.horizontalAlign,
    containerAlign: blok.verticalAlign,
    padding: blok.padding,
    invertTextColor: blok.invertTextColor,
    overflow: blok.overflow,
  };
  if (blok.hide) return <></>;

  return (
    <div
      id={blok.anchor}
      ref={domRef}
      style={{
        overflow: blok.overflow ? "visible" : "hidden",
      }}
      className={
        styles.section +
        " " +
        (isVisible ? styles.isVisible : "") +
        " " +
        (hasAnimated ? styles.hasAnimated : "") +
        " " +
        (wasAbove ? styles.wasAbove : "")
      }
      {...sbEditable(blok)}
    >
      {/* <div className={styles.graphics}>
        {blok?.graphics?.map((childBlok: any, index: number) => (
          <DynamicComponent blok={childBlok} key={index} />
        ))}
      </div> */}
      <Section {...props}>
        <div
          className={styles.content}
          style={{ overflow: props.overflow ? "visible" : "hidden" }}
        >
          {blok.body.map((childBlok: any, index: number) => (
            <DynamicComponent blok={childBlok} key={index} />
          ))}
        </div>
      </Section>
    </div>
  );
};

interface CompProps {
  className: string;
  backgroundColor: string;
  backgroundImage?: SBImage;
  backgroundSize?: string;
  backgroundPosition?: string;
  containerMaxWidth: string;
  theme: string;
  containerJustify: "left" | "center" | "right";
  containerAlign: "top" | "center" | "bottom";
  padding: string;
  invertTextColor: boolean;
  overflow: boolean;
  height: string;
}

export const Section: FC<CompProps> = (props) => {
  const {
    backgroundColor,
    backgroundImage,
    padding,
    overflow,
    containerMaxWidth,
    className,
    invertTextColor,
    theme,
    containerJustify,
    containerAlign,
    backgroundPosition,
    backgroundSize,
    height,
  } = props;
  // const {
  //   url: backgroundImageURL,
  //   quality,
  //   paths,

  //   noImage,
  // } = useImage(backgroundImage);

  // console.log("section image updated", {
  //   backgroundImage,
  //   backgroundImageURL,
  //   quality,
  //   paths,
  //   noImage,
  // });

  const style = {
    normal: {
      padding: cleanVars(padding),
      backgroundColor: !emptyString(backgroundColor)
        ? cleanColour(backgroundColor)
        : undefined,
      // backgroundImage: !noImage ? `url('${backgroundImageURL}')` : undefined,
      backgroundPosition: !emptyString(backgroundPosition)
        ? backgroundPosition
        : undefined,
      backgroundRepeat: "no-repeat",
      backgroundSize: !emptyString(backgroundSize) ? backgroundSize : undefined,
      overflow: overflow ? "visible" : "hidden",
      boxSizing: "border-box",
      minHeight: !emptyString(height) ? height : undefined,
    },
    container: {
      maxWidth: !emptyString(containerMaxWidth) ? containerMaxWidth : undefined,
      overflow: overflow ? "visible" : "hidden",
    },
  };
  return (
    <div
      data-theme={theme}
      className={[
        styles.Section,
        className,
        styles["invert-" + invertTextColor],
      ].join(" ")}
      style={
        {
          ...style.normal,
        } as any
      }
    >
      {backgroundImage && !emptyString(backgroundImage?.filename) ? (
        <MultiQualityImage
          image={backgroundImage}
          css={{
            position: "absolute",
            inset: 0,
            zIndex: 0,
          }}
          options={{
            fit: !emptyString(backgroundSize) ? backgroundSize : undefined,
            position: !emptyString(backgroundPosition)
              ? backgroundPosition
              : undefined,
            repeat: "no-repeat",
          }}
        />
      ) : null}
      <div
        className={[
          styles.container,
          "justify-" + containerJustify,
          "align-" + containerAlign,
        ].join(" ")}
        style={{
          ...style.container,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default BlokSection;
