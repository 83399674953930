import { useEffect, useState } from "react";
import { Button, ButtonPresets } from "../button/Button";
import { useMediaQuery } from "usehooks-ts";

export function Pagination({
  page = 0,
  onChange,
  totalPages = 1,
  range: propRange = 5,
}: {
  onChange: (index: number) => void;
  page: number;
  range: number;
  totalPages: number;
}) {
  const mobile = useMediaQuery("(max-width: 1000px)");
  const [active, setActive] = useState<number>(page);
  const [range, setRange] = useState<number>(propRange);
  const allNumbers = Array.from({ length: totalPages }, (_, index) => index);

  const start = Math.max(page - range, 0);
  const end = Math.min(page + range, totalPages - 1);
  const numbersToShow = [...allNumbers].slice(start, end + 1);

  useEffect(() => {
    setActive(page);
  }, [page]);

  useEffect(() => {
    setRange(mobile ? 0 : propRange);
  }, [mobile, propRange]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: mobile ? 0 : 16,
        justifyContent: "space-between",
      }}
    >
      <Button
        disabled={active === 0}
        onClick={() => {
          onChange(active - 1);
          setActive(active - 1);
        }}
        label={"Previous"}
        noOutline
        underline
        backgroundTheme="white"
        iconLeft="ArrowBack"
      />
      {numbersToShow.map((number: number, index: number) => (
        <Button
          key={`pageButton_${number}`}
          active={active === number}
          backgroundTheme="white"
          onClick={() => {
            onChange(number);
            setActive(number);
          }}
          label={(number + 1).toString()}
          preset={ButtonPresets.pagination}
        />
      ))}
      <Button
        disabled={active + 1 === totalPages}
        onClick={() => {
          onChange(active + 1);
          setActive(active + 1);
        }}
        label={"Next"}
        noOutline
        backgroundTheme="white"
        underline
        iconRight="ArrowForward"
      />
    </div>
  );
}
