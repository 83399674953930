import { Swiper } from "swiper/react";
import { FC } from "react";
import grid from "./components/grid/Grid";
import page from "./components/page/Page";
import project from "./components/project/Project";
import section from "./components/section/Section";
import icon from "./components/icon/BlokIcon";
import columns from "./components/columns/Columns";
import flex from "./components/flex/Flex";
import image from "./components/image/Image";
import content from "./components/content/Content";
import formBlock from "./components/form/FormBlock";
import spacer from "./components/Spacer/Spacer";
import iconCard from "./components/iconCard/IconCard";
import button from "./components/button/Button";
import steps from "./components/steps/Steps";
import Graphic from "./components/graphic/Graphic";
import html from "./components/HTML/HTML";
import itemCard from "./components/itemCard/ItemCard";
import itemGrid from "./components/itemGrid/ItemGrid";
import map from "./components/map/Map";
import iconItems from "./components/iconItems/IconItems";
import list from "./components/list/List";
import swipecards from "./components/swipecards/SwipeCards";
import footerContent from "./components/footerContent/FooterContent";
import blogPage from "./components/blogPost/BlogPage";
import blogs from "./components/allBlogs/Blogs";
import imageSlideShow from "./components/imageSlideShow/ImageSlideShow";
import blogCard from "./components/blogCard/BlogCard";
import blogCategory from "./components/blogCategory/BlogCategory";
import similarBlogs from "./components/similarBlogs/SimilarBlogs";
import projects from "./components/projects/Projects";
import accordion from "./components/accordion/Accordion";
import header from "../layout/Header";
import CookiesBanner from "../layout/CookiesBanner";
import CallToAction from "../layout/CallToAction";
import cycleText from "./components/cycleText/CycleText";
import footerBlok from "./components/footerBlok/FooterBlok";
import link from "./components/link/BlokLink";
import Iframe from "./components/Iframe/Iframe";
import table from "./components/table/Table";
import footer from "../layout/Footer";
import newsletter from "./components/newsletter/Newsletter";
// import newsletter from "./components/projects/Projects";
import swiper from "./components/swiper/Swiper";
import container from "./components/container/Container";

// Map Storyblok components to Next.js components
const ComponentMap: Record<string, FC<any>> = {
  accordion,
  container,
  swiper,
  newsletter,
  CookiesBanner,
  CallToAction,
  grid,
  iconCard,
  cycleText,
  iconItems,
  footerBlok,
  button,
  spacer,
  formBlock,
  imageSlideShow,
  blogs,
  itemCard,
  project,
  blogCategory,
  page,
  icon,
  Graphic,
  section,
  itemGrid,
  table,
  blogPage,
  Iframe,
  blogCard,
  columns,
  similarBlogs,
  list,
  link,
  flex,
  content,
  footer,
  header,
  image,
  html,
  steps,
  projects,
  map,
  swipecards,
  footerContent,
};

export default ComponentMap;
