import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { StoryblokComponent } from "storyblok-js-client";
import Icon from "../icon/Icon";
import { ListComp } from "./ListComp";

interface Schema {
  items: StoryblokComponent<string>[];
  type: string;
  textColor: string;
  iconColor: string;
  icon: string;
  gap: number;
  topHeader: boolean;
  fontSize: number;
}

interface Props {
  blok: Schema;
}

const BlokList: FC<Props> = ({ blok }) => {
  let icon = blok.type === "Custom" ? <Icon name={blok.icon} /> : blok.icon;

  const props = {
    type: blok.type,
    textColor: blok.textColor,
    iconColor: blok.iconColor,
    icon: icon,
    gap: blok.gap,
    topHeader: blok.topHeader,
  };

  return (
    <ListComp {...props} {...sbEditable(blok)}>
      {blok.items.map((childBlok: any, index: number) => {
        let url =
          childBlok.link.linktype === "story"
            ? childBlok.link.cached_url
              ? "/" + childBlok.link.cached_url
              : ""
            : childBlok.link.url
            ? childBlok.link.url
            : "";

        let li = childBlok.label;
        if (url)
          li = (
            <a
              title={childBlok.title ? childBlok.title : childBlok.label}
              href={url}
            >
              {childBlok.label}
            </a>
          );

        return (
          <li
            style={{
              fontSize: (blok.fontSize ? blok.fontSize : 16) + "px",
            }}
            key={index}
          >
            {li}
          </li>
        );
      })}
    </ListComp>
  );
};

export default BlokList;
