import { FC, useContext, useEffect, useRef, useState } from "react";
import { StoryblokComponent } from "storyblok-js-client";

import BlokSection from "../section/Section";
import moment from "moment";
import { ButtonPresets } from "../button/Button";

interface Blok {
  content: StoryblokComponent<string>[];
  timeToRead: string;
  title: string;
  excerpt: string;
  date: string;
  category: string;
  author: string;
  authorImage: any;
}

interface PageProps {
  blok: Blok;
}

export const useMediaQueryNew = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

const Spacer_Top = {
  type: "blok",
  attrs: {
    body: [
      {
        height: "120px",
        component: "spacer",
        heightMobile: "72px",
      },
    ],
  },
};
const BACK_LINK = {
  type: "blok",
  attrs: {
    body: [
      {
        link: {
          url: "/blogs/",
          cached_url: "/blogs/",
          href: "/blogs/",
          uuid: "30adfc7c-c766-47ce-b7f6-e3285268187f",
          anchor: null,
          target: "_self",
          linktype: "url",
        },
        size: "medium",
        label: "Back to articles",
        shade: false,
        circle: false,
        filled: false,
        bordered: false,
        disabled: false,
        preset: ButtonPresets.backLink,
        iconLeft: "ArrowBack",
        className: "backLink",
        component: "button",
        presetName: "tertiary",
        fullWidthMobile: false,
      },
    ],
  },
};

const AUTHOR_SECTION = (blok: Blok) => ({
  bloks: [
    {
      gap: "24px",
      items: [
        {
          fit: "cover",
          horizontalAlign: "left",
          image: blok.authorImage,
          width: "64px",
          height: "64px",
          altText: "Image of the author",
          position: "50% 50%",
          component: "image",
          borderRadius: "50%",
        },
        {
          body: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: `${blok.author}`,
                    type: "text",
                    marks: [
                      {
                        type: "textStyle",
                        attrs: {
                          color: "#252833",
                        },
                      },
                    ],
                  },
                  {
                    type: "hard_break",
                  },
                  {
                    text: `${moment(blok.date).fromNow()}  •  ${
                      blok.timeToRead
                    } min read`,
                    type: "text",
                    marks: [
                      {
                        type: "styled",
                        attrs: {
                          class: "smallText",
                        },
                      },
                      {
                        type: "textStyle",
                        attrs: {
                          color: "#696C80",
                          textTransform: "capitalize",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          component: "content",
        },
      ],
      overflow: true,
      component: "columns",
      horizontalAlign: "left",
      verticalAlign: "center",
      wrap: "no-wrap",
      columns: 0,
      columnsSmall: 0,
    },
  ],

  component: "container",
});

// const BlogPost: FC<PageProps> = ({ blok }) => {
//   return <></>;
// };
const BlogPost: FC<PageProps> = ({ blok }) => {
  const { title, excerpt, content, date, category } = blok;

  const section = {
    body: [
      {
        gap: "32",
        wrap: "wrap",
        items: [
          {
            body: {
              type: "doc",
              content: [BACK_LINK, Spacer_Top],
            },
            fontSize: "16",
            minWidth: "",
            className: "",
            component: "content",
            textAlign: "left",
            textColor: "",
          },
        ],
        columns: "2",
        overflow: true,
        component: "columns",
        columnsLarge: "2",
        columnsSmall: "1",
        verticalAlign: "top",
        horizontalAlign: "space-between",
        columnsExtraSmall: "1",
      },
      {
        gap: "32",
        wrap: "wrap",
        items: [
          {
            body: {
              type: "doc",
              content: [
                {
                  type: "heading",
                  attrs: {
                    level: 1,
                  },
                  content: [
                    {
                      text: title,
                      type: "text",
                    },
                  ],
                },
                {
                  type: "blok",
                  attrs: {
                    body: [AUTHOR_SECTION(blok)],
                  },
                },
              ],
            },
            component: "content",
          },
          {
            items: [
              ...(content || []),
              {
                height: "52px",
                component: "spacer",
                heightMobile: "40px",
              },
              {
                component: "button",
                special: "share",
              },
            ],
            columns: "1",
            overflow: true,
            component: "columns",
            verticalAlign: "center",
          },
        ],
        columns: "2",
        overflow: true,
        component: "columns",
        columnsLarge: "2",
        columnsSmall: "1",
        verticalAlign: "top",
        horizontalAlign: "space-between",
        columnsExtraSmall: "1",
      },
      {
        id: "",
        image: {
          id: 10099579,
          alt: "",
          name: "",
          focus: "",
          title: "",
          source: "",
          filename:
            category === "news"
              ? "https://a.storyblok.com/f/235274/158x663/84637e7a53/graphic_text_news_1600.webp"
              : "https://a.storyblok.com/f/235274/280x1406/fd568a3ec2/graphic_text_knowledge_1600.webp",

          copyright: "",
          fieldtype: "asset",
          meta_data: {},
          is_external_url: false,
        },
        style: "",
        insetM: "auto auto 0px 40px",
        insetS: "",
        widthM: "min(20vw, 140px)",
        widthS: "0",
        heightM: "auto",
        heightS: "0",
        className: "",
        component: "Graphic",
      },
    ],
    backgroundImage: {
      id: 10099581,
      alt: "",
      name: "",
      focus: "",
      title: "",
      source: "",
      filename:
        "https://a.storyblok.com/f/235274/3456x3090/99847d00f8/graphic_multiple_webp.webp",
      copyright: "",
      fieldtype: "asset",
      meta_data: {},
      is_external_url: false,
    },
    theme: "white-off",
    padding: "@padding 0 @padding-article",
    overflow: true,
    component: "section",
    verticalAlign: "center",
    horizontalAlign: "left",
  };
  return (
    <article className="blogPage">
      <BlokSection blok={section} />
    </article>
  );
};

export default BlogPost;
