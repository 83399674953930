import { useState, useRef, useEffect, useMemo } from "react";
import { emptyString } from "../content/Content";
import styles from "./Image.module.scss";
import useScreenWidth, { useElementWidth } from "./useWidth";

export interface SBImage {
  alt: string;
  copyright: string;
  fieldtype: string;
  filename: string;
  focus: string;
  id: number;
  is_external_url: boolean;
  meta_data: Record<string, any>;
  name: string;
  source: string;
  title: string;
}

const useImage = ({
  image: { filename: url = "" },
  maxWidth,
  ...props
}: SBImage | any) => {
  const noImage = !props || emptyString(url);
  const [paths, setPaths] = useState<any>();
  const [backgroundImage, setBackgroundImage] = useState<string>(paths?.low);
  const [quality, setQuality] = useState<string>("low");

  useEffect(() => {
    const widthString = maxWidth ? `${maxWidth}x0/` : ``;
    setPaths({
      low: `${url}/m/${widthString}filters:quality(50)`,
      medium: `${url}/m/${widthString}filters:quality(80)`,
      high: `${url}/m/${widthString}filters:quality(98)`,
      full: `${url}/m/${widthString}filters:quality(100)`,
    });
  }, [maxWidth, url]);

  useEffect(() => {
    if (noImage) return;
    if (!paths) return;
    const { low, medium, high, full } = paths;
    const loadImage = (quality: string, src: string) => {
      const image = new Image();
      image.src = src;

      image.onload = () => {
        setTimeout(() => {
          setBackgroundImage(src);
          setQuality(quality);

          if (quality === "low") {
            loadImage("medium", medium);
          } else if (quality === "medium") {
            loadImage("high", high);
          } else if (quality === "high") {
            loadImage("full", full);
          }
        }, 10);
      };
    };

    loadImage("low", low);

    return () => {};
  }, [paths, noImage]);

  return { url: backgroundImage, quality, paths, noImage };
};
export default useImage;

export type ObjectFit = "contain" | "cover" | "fill" | "none" | "scale-down";

export type ObjectPosition =
  | "bottom"
  | "center"
  | "left"
  | "right"
  | "top"
  | (string & {});

export function MultiQualityImage({
  image,
  css,
  options = {
    fit: "cover",
    position: "50% 50%",
    repeat: "no-repeat",
  },
}: {
  image: SBImage;
  css: any;
  options?: {
    fit: ObjectFit | any;
    position: ObjectPosition | any;
    repeat:
      | "no-repeat"
      | "repeat"
      | "repeat-x"
      | "repeat-y"
      | "round"
      | "space"
      | any;
  };
}) {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement | any>(null);

  const [elementWidth] = useElementWidth(imageWrapperRef);
  const { url, quality } = useImage({
    image,
    maxWidth: elementWidth < 400 ? 600 : elementWidth + 200,
  });
  const fit = options?.fit?.split(" ") || ["cover"];
  const position = options.position;
  const contain = fit?.length > 1;
  return (
    <div className={styles.MultiImage} style={css} ref={imageWrapperRef}>
      <img
        ref={imageRef}
        src={url}
        alt={image.alt}
        data-quality={quality}
        data-src={url}
        style={{
          position: "absolute",

          objectFit: contain ? "contain" : "cover",
          width: contain ? fit[0] : "100%",
          height: contain ? fit[1] || fit[0] : "100%",
          inset: !contain ? "0" : position,
          objectPosition: contain ? "0 0" : position,
        }}
      />
    </div>
  );
}
