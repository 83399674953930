import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import StoryblokContext from "../../store/StoryblokContext";
import DynamicComponent from "../DynamicComponent";
import { getStorySettings } from "../storyblok/StoryblokData";
import { emptyString } from "../storyblok/components/content/Content";
import { Button, ButtonPresets } from "../storyblok/components/button/Button";
const CookieBanner = () => {
  const ctx = useContext(StoryblokContext);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const cookieAccepted = getCookie("cookieAccepted");
    if (cookieAccepted === "true") {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieAccepted", "true", 365); // Set cookie for 365 days
    setAccepted(true);
  };

  if (!ctx?.shared?.stories?.cookiesbanner) return null;
  const { content = [], theme } = getStorySettings(
    ctx?.shared?.stories?.cookiesbanner
  );

  if (accepted) {
    return <></>; // Don't render the banner if it's already accepted
  }
  return (
    <section
      data-theme={theme || "warning"}
      className="cookieBanner"
      style={{
        background: emptyString(theme) ? "var(--cookiebanner-bg)" : undefined,
        padding: "var(--cookiebanner-pd)",
        width: "100%",
        position: "fixed",
        bottom: "0",
        left: "0",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          width: "100%",
          margin: "0 auto",
          paddingLeft: "var(--cookiebanner-container-pd)",
          paddingRight: "var(--cookiebanner-container-pd)",
          maxWidth: "var(--cookiebanner-container)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          className="cookieBannerContent"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          {content.map((childBlok: any, index: number) => (
            <DynamicComponent blok={childBlok} key={index} />
          ))}
        </div>
        <div
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            label="Accept"
            preset={ButtonPresets.secondary}
            onClick={handleAccept}
            fullWidthMobile={true}
          />
        </div>
      </div>
    </section>
  );
};

export default CookieBanner;

// Function to get a cookie value
function getCookie(name: string): string | null {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}

// Function to set a cookie value
function setCookie(name: string, value: string, daysToExpire?: number): void {
  let cookie = name + "=" + encodeURIComponent(value);

  if (daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);
    cookie += "; expires=" + expirationDate.toUTCString();
  }

  document.cookie = cookie;
}
