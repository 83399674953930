import { FC, useRef, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../../DynamicComponent";
import { StoryblokComponent } from "storyblok-js-client";
import { ThemeProps } from "../../../Theme";
import Icon from "../icon/Icon";
import styles from "./FormBlock.module.scss";
import { Button, ButtonPresets } from "../button/Button";
import { InputText } from "./inputs/text/InputText";
import { InputTextArea } from "./inputs/textarea/InputTextArea";
import { InputSelect } from "./inputs/select/InputSelect";
import { InputCheckBox } from "./inputs/checkbox/InputCheckBox";
import { URLfromLink } from "../footerBlok/FooterBlok";
import { useRouter } from "next/router";

interface Schema {
  fields: StoryblokComponent<string>[];
  columns: string;
  name: string;
  title: string;
  excerpt: string;
  submitText: string;
  redirectLink: any;
  backgroundTheme: string;
}

interface Props {
  blok: Schema;
}

const BlokFormBlock: FC<Props> = ({ blok }) => {
  const router = useRouter();

  let fields: any = [];
  const urlRedirect = blok.redirectLink
    ? URLfromLink(blok.redirectLink)
    : undefined;

  blok.fields.map((field: any) => {
    fields.push({
      type: field.type,
      slug: field.slug,
      label: field.label,
      required: field.required,
      width: field.width,
      options: field.options,
      placeholder: field.placeholder,
      initialValue: field.initialVal,
    });
  });

  function submitForm(form: any, fields: any) {
    let myForm: any = form;
    let formData: any = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        console.log(formData);

        if (urlRedirect) {
          router.push(urlRedirect);
        }
      })
      .catch((error) => alert(error));
  }
  let dropdownIcon = <Icon name={"chevrondown"} />;

  const props = {
    ...blok,
    title: blok.title,
    excerpt: blok.excerpt,
    fields: fields,
    columns: blok.columns,
    name: blok.name,
    submitText: blok.submitText,
    backgroundTheme: blok.backgroundTheme,
    icons: {
      dropdown: {
        custom: true,
        icon: dropdownIcon,
      },
    },
    formAttrs: {
      name: blok.name,
      method: "POST",
      netlify: "true",
      "data-netlify": "true",
      "netlify-honeypot": "bot-field",
    },
    submitButtonProps: ThemeProps.getPrimaryButtonProps({
      label: "Submit",
      onClick: (form: any, fields: any) => {
        submitForm(form, fields);
      },
    }),
  };
  return <FormBlock {...props} editable={sbEditable(blok)}></FormBlock>;
};

interface CompProps {
  title?: string;
  excerpt?: string;
  submitText?: string;
  columns?: string;
  formAttrs: any;
  submitButtonProps: any;
  icons: any;
  fields: any;
  backgroundTheme: string;
  name: string;
  editable: any;
}

export const FormBlock: FC<CompProps> = (props) => {
  const {
    title,
    excerpt,
    submitText,
    columns,
    formAttrs,
    submitButtonProps,
    name,
    icons,
    fields,
    backgroundTheme,
    editable,
  } = props;

  const [failed, setFailed] = useState<any>({});
  // const [hover, setHover] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const form = useRef();

  let fieldsList: any = [];

  fields.map((field: any, index: number) => {
    let fieldElement = <></>;
    let width =
      "calc(100% / " +
      columns +
      " * " +
      field.width +
      ` - ${columns == "1" ? "0px" : "24px"})`;
    let failedField: any = failed[field.slug];
    switch (field.type) {
      case "email":
        fieldElement = (
          <InputText
            backgroundColor="#fff"
            backgroundColorHover="#fff"
            textColor="#080808"
            textColorHover="#080808"
            slug={field.slug}
            label={field.label}
            required={field.required}
            type={"email"}
            failed={failedField}
            disabled={submitted}
          />
        );
        break;
      case "checkbox":
        fieldElement = (
          <InputCheckBox
            slug={field.slug}
            label={field.label}
            required={field.required}
            failed={failedField}
            disabled={submitted}
            checked={Boolean(field.initialValue === "true")}
          />
        );
        break;
      case "textarea":
        fieldElement = (
          <InputTextArea
            backgroundColor="#fff"
            backgroundColorHover="#fff"
            textColor="#080808"
            textColorHover="#080808"
            slug={field.slug}
            label={field.label}
            required={field.required}
            placeholder={field.placeholder}
            maxChars={field.maxChars}
            failed={failedField}
            disabled={submitted}
          />
        );
        break;
      case "select":
        fieldElement = (
          <InputSelect
            backgroundColor="#fff"
            backgroundColorHover="#fff"
            label={field.label}
            required={field.required}
            placeholder={field.placeholder}
            options={field.options}
            textColor="#080808"
            textColorHover="#080808"
            failed={failedField}
            disabled={submitted}
            iconCustom={icons.dropdown ? icons.dropdown.custom : false}
            icon={icons.dropdown ? icons.dropdown.icon : false}
          />
        );
        break;
      default:
      case "text":
        fieldElement = (
          <InputText
            backgroundColor="#fff"
            backgroundColorHover="#fff"
            textColor="#080808"
            textColorHover="#080808"
            slug={field.slug}
            label={field.label}
            required={field.required}
            type={"text"}
            failed={failedField}
            disabled={submitted}
          />
        );
        break;
    }

    fieldsList.push(
      <div
        className={styles.FormField}
        style={{ width: width }}
        {...sbEditable(field)}
      >
        {fieldElement}
      </div>
    );
  });

  function getFieldsFromForm() {
    const formFields = document
      .getElementById(`form_${props.name}`)
      ?.querySelectorAll("[data-field]");

    if (!formFields) {
      return false;
    }
    const fieldsFilled: any = {};

    fields.forEach((field: any) => {
      let input: any = undefined;
      formFields.forEach((i) => {
        if (i.getAttribute("data-field") === field.slug) {
          input = i;
        }
      });
      if (!input) return;
      fieldsFilled[field.slug] = {
        value: input?.value,
        field,
        required: field.required,
        input: input,
        parent: input?.parentNode,
        filled: input?.parentNode?.classList?.contains("filled"),
      };
    });
    return fieldsFilled;
  }

  function validateFields(e: any) {
    const formFields = getFieldsFromForm();

    if (!formFields) {
      alert("Error no fields");
      return false;
    }

    let failed: any = {};
    let failedCount = 0;
    Object.values(formFields).map((field: any, index) => {
      if (field.required && !field.filled) {
        failed[field.slug] = {
          failed: true,
          error: "* This field is required",
        };
        failedCount++;
      }
    });

    if (failedCount > 0) {
      setFailed(failed);
    } else {
      return true;
    }
    return false;
  }

  function getFields(e: any) {
    const formFields = getFieldsFromForm();

    if (!formFields) {
      alert("Error no fields");
      return false;
    }
    return Object.values(formFields);
  }

  return (
    <div
      className={styles.FormBlock}
      id={`form_${props.name}`}
      {...editable}
      data-theme={props.backgroundTheme}
      style={{
        backgroundColor: "transparent",
      }}
    >
      <div className={styles.FormForm}>
        {title && <h3>{title}</h3>}
        {excerpt && <p>{excerpt}</p>}
        <form
          ref={form}
          {...formAttrs}
          onSubmit={(e) => {
            e.preventDefault();
            (window as any)["gtag"]("event", "form_submission", {
              event_category: "form",
              event_label: "contact_form",
            });
            return false;
          }}
        >
          <input type="hidden" name="form-name" value={name} />
          {fieldsList}

          {submitted ? (
            <p className={"submitted"}>
              {submitText || "Thankyou, we will be in touch."}
            </p>
          ) : (
            <Button
              {...submitButtonProps}
              fullWidthMobile={true}
              filled={true}
              preset={ButtonPresets.primary}
              backgroundTheme="white"
              onClick={(e) => {
                if (validateFields(e)) {
                  submitButtonProps.onClick(
                    form.current,
                    getFields(form.current)
                  );
                  setSubmitted(true);
                }
              }}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default BlokFormBlock;
