import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";

import { StoryblokComponent } from "storyblok-js-client";
import DynamicComponent from "../../../DynamicComponent";
import { ItemGrid } from "./Obj/ItemGrid";

interface Schema {
  items: StoryblokComponent<string>[];
  columns: number;
  columnsLarge: number;
  columnsSmall: number;
  columnsExtraSmall: number;
  gap: number;
  horizontalAlign: string;
  horizontalAlignLarge: string;
  horizontalAlignSmall: string;
  horizontalAlignExtraSmall: string;
  textAlign: string;
  title: string;
  subtitle: string;
  excerpt: string;
  linkText: string;
  linkUrl: any;
  linkIcon: string;
}

interface Props {
  blok: Schema;
}

const BlokItemGrid: FC<Props> = ({ blok }) => {
  const props = {
    ...blok,
    columns: blok.columns,
    columnsLarge: blok.columnsLarge,
    columnsSmall: blok.columnsSmall,
    columnsExtraSmall: blok.columnsExtraSmall,
    gap: blok.gap,
    subtitle: blok.subtitle,
    title: blok.title,
    excerpt: blok.excerpt,
    linkText: blok.linkText,
    linkUrl: blok.linkUrl.url,
    linkIcon: blok.linkIcon,
    justify: blok.horizontalAlign,

    justifyLarge: blok.horizontalAlignLarge
      ? blok.horizontalAlignLarge
      : blok.horizontalAlign,
    justifySmall: blok.horizontalAlignSmall
      ? blok.horizontalAlignSmall
      : blok.horizontalAlign,
    justifyExtraSmall: blok.horizontalAlignExtraSmall
      ? blok.horizontalAlignExtraSmall
      : blok.horizontalAlign,
    textAlign: blok.textAlign,
  };
  return (
    <ItemGrid style={{ width: "100%" }} {...props} {...sbEditable(blok)}>
      {blok.items.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </ItemGrid>
  );
};

export default BlokItemGrid;
