import { Children, FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../../DynamicComponent";
import { StoryblokComponent } from "storyblok-js-client";
import styles from "./Flex.module.scss";

interface Schema {
  items: StoryblokComponent<string>[];
  gap: number;
  wrap: string;
  verticalAlign: string;
  horizontalAlign: string;
  verticalAlignLarge: string;
  horizontalAlignLarge: string;
  verticalAlignSmall: string;
  horizontalAlignSmall: string;
  verticalAlignExtraSmall: string;
  horizontalAlignExtraSmall: string;
  textAlign: string;
  columnSizesCustom: any;
  overflow: boolean;
}

interface Props {
  blok: Schema;
}

const BlokFlex: FC<Props> = ({ blok }) => {
  const props = {
    ...blok,
    align: blok.verticalAlign,
    justify: blok.horizontalAlign,
    alignLarge: blok.verticalAlignLarge
      ? blok.verticalAlignLarge
      : blok.verticalAlign,
    justifyLarge: blok.horizontalAlignLarge
      ? blok.horizontalAlignLarge
      : blok.horizontalAlign,
    alignSmall: blok.verticalAlignSmall
      ? blok.verticalAlignSmall
      : blok.verticalAlign,
    justifySmall: blok.horizontalAlignSmall
      ? blok.horizontalAlignSmall
      : blok.horizontalAlign,
    alignExtraSmall: blok.verticalAlignExtraSmall
      ? blok.verticalAlignExtraSmall
      : blok.verticalAlign,
    justifyExtraSmall: blok.horizontalAlignExtraSmall
      ? blok.horizontalAlignExtraSmall
      : blok.horizontalAlign,
    gap: blok.gap,
    wrap: blok.wrap,

    textAlign: blok.textAlign,
    overflow: blok.overflow,
    indexSizes: blok.columnSizesCustom
      ? JSON.parse(blok.columnSizesCustom)
      : null,
  };
  return (
    <Flex {...props} {...sbEditable(blok)}>
      {blok.items.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </Flex>
  );
};

interface ComponentProps {
  className?: string;

  gap?: number;
  wrap?: string;

  justify?: string;
  justifyLarge?: string;
  justifySmall?: string;
  justifyExtraSmall?: string;

  align?: string;
  alignLarge?: string;
  alignSmall?: string;
  alignExtraSmall?: string;

  textAlign?: string;

  indexSizes: any;
  overflow: boolean;
}

export const Flex: FC<ComponentProps> = (props) => {
  const {
    className,

    gap,
    wrap,
    justify,
    justifyLarge,
    justifySmall,
    justifyExtraSmall,
    align,
    alignLarge,
    alignSmall,
    alignExtraSmall,
    textAlign,
    indexSizes,
    overflow,
    children,
  } = props;

  const style = {
    normal: {
      "--grid-gap": gap + "px",
      overflow: overflow ? "visible" : "hidden",
    },
    grid: {
      "--grid-gap": gap + "px",
      gap: gap?.toString().endsWith("px") ? gap : gap + "px",
      overflow: overflow ? "visible" : "hidden",
      flexWrap: wrap,
    },
  };

  const columnClasses = [
    styles.Column,
    styles["align-" + align?.toLowerCase()],
    styles["align-large-" + alignLarge?.toLowerCase()],
    styles["align-small-" + alignSmall?.toLowerCase()],
    styles["align-extra-small-" + alignExtraSmall?.toLowerCase()],
  ].join(" ");

  let gridItems: any = [];

  Children.map(children, (child: any, index: number) => {
    if (child.type) {
      const columnStyle = {
        "--grid-gap": gap + "px",
        overflow: overflow ? "visible" : "hidden",
      };

      gridItems.push(
        <div
          className={columnClasses}
          style={columnStyle}
          // index={index}
          key={index}
        >
          {child}
        </div>
      );
    }
  });

  return (
    <div
      className={[styles.Flex, className].join(" ")}
      style={{
        ...style.normal,
      }}
    >
      <div
        className={[
          styles.FlexGrid,
          styles["justify-" + justify],
          styles["justify-large-" + justifyLarge?.toLowerCase()],
          styles["justify-small-" + justifySmall?.toLowerCase()],
          styles["justify-extra-small-" + justifyExtraSmall?.toLowerCase()],
          "text-" + textAlign,
        ].join(" ")}
      >
        {gridItems}
      </div>
    </div>
  );
};

export default BlokFlex;
