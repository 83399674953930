import { FC } from "react";
import {
  MARK_STYLED,
  render,
  NODE_HEADING,
} from "storyblok-rich-text-react-renderer";
import BlokButton from "../button/Button";
// import BlokList from "./List";
import BlokSpacer from "../Spacer/Spacer";
import moment from "moment";
import BlokImage from "../image/Image";
import Content, { slugify } from "./Content";
import BlokFlex from "../flex/Flex";
import CycleText from "../cycleText/CycleText";
import Container from "../container/Container";
import Newsletter from "../newsletter/Newsletter";
import Table from "../table/Table";

interface Props {
  content: any;
}

const RichtextOB: FC<Props> = ({ content }) => {
  return (
    <div>
      {render(content, {
        blokResolvers: {
          ["button"]: (props: any) => <BlokButton blok={props} />,
          // ['list']: (props: any) => <BlokList blok={ props } />,
          ["flex"]: (props: any) => <BlokFlex blok={props} />,
          ["spacer"]: (props: any) => <BlokSpacer blok={props} />,
          ["image"]: (props: any) => <BlokImage blok={props} />,
          ["content"]: (props: any) => <Content blok={props} />,
          ["cycleText"]: (props: any) => <CycleText blok={props} />,
          ["container"]: (props: any) => <Container blok={props} />,
          ["table"]: (props: any) => <Table blok={props} />,

          ["newsletter"]: (props: any) => <Newsletter blok={props} />,

          ["date"]: (props: any) => {
            return (
              <span>
                {props.date
                  ? moment(props.date).format(props.format)
                  : moment().format(props.format)}
              </span>
            );
            // return (<span>hello</span>)
          },
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, props) => {
            if (!children || (Array.isArray(children) && children.length === 0))
              return <></>;
            let object = <></>;
            let anchor = Array.isArray(children)
              ? slugify(
                  children[0].type ? children[0].props.children : children[0]
                )
              : "none";
            switch (props.level) {
              case 1:
                object = (
                  <h1
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h1>
                );
                break;
              case 2:
                object = (
                  <h2
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h2>
                );
                break;
              case 3:
                object = (
                  <h3
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h3>
                );
                break;
              case 4:
                object = (
                  <h4
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h4>
                );
                break;
              case 5:
                object = (
                  <h5
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h5>
                );
                break;
              case 6:
                object = (
                  <h6
                    id={anchor}
                    className={anchor !== "none" ? "headingWithAnchor" : ""}
                  >
                    {children}
                  </h6>
                );
                break;
            }
            return object;
          },
        },

        textResolver: (text) => {
          return text.replaceAll("{{year}}", moment().format("yyyy"));
        },
      })}
    </div>
  );
};

export default RichtextOB;
