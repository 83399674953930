import { useEffect, useState } from "react";
import { Button, ButtonPresets } from "../button/Button";
import { emptyString } from "../content/Content";

interface Props {
  toggled: boolean;
  disabled?: boolean;
  label: string;
  deselectable?: boolean;
  onChange: (toggled: boolean) => void;
}

export function ToggleButton({
  toggled = false,
  deselectable = true,
  onChange,
  label,
  disabled = false,
}: Props) {
  const [active, setActive] = useState(toggled);

  useEffect(() => {
    setActive(toggled);
  }, [toggled]);

  const handleClick = () => {
    if (!deselectable && active) return;
    setActive(!active);
    onChange && onChange(!active);
  };

  return (
    <Button
      preset={ButtonPresets.toggleButton}
      active={active}
      iconLeft={active ? "Check" : undefined}
      label={label}
      onClick={handleClick}
      backgroundTheme="white"
      disabled={disabled}
    />
  );
}

export function ToggleButtons({
  options,
  onChange,
  selected,
}: {
  onChange: (toggled: string[]) => void;
  options: string[];
  selected: string[];
}) {
  const [active, setActive] = useState<string[]>(selected);

  useEffect(() => {
    const saneSelected = selected.filter(
      (s) => !emptyString(s) && options.indexOf(s) !== -1
    );
    setActive(saneSelected);
  }, [selected, options]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 12,
        flexWrap: "wrap",
      }}
      key={`toggleButtons_${JSON.stringify(active || [])}`}
    >
      <ToggleButton
        toggled={!active || active.length === 0}
        deselectable={false}
        onChange={(toggled) => {
          setActive([]);
          onChange && onChange([]);
        }}
        key={`toggleButton_all_${JSON.stringify(active || [])}`}
        label="All"
      />
      {options.map((option: string) => (
        <ToggleButton
          key={`toggleButton_${option}`}
          toggled={active.includes(option)}
          onChange={(toggled) => {
            let newActive = [...active];
            if (toggled) {
              newActive = [...newActive, option];
            } else {
              newActive = newActive.filter((a) => a !== option);
            }

            onChange && onChange(newActive);
            setActive(newActive);
          }}
          label={option}
        />
      ))}
    </div>
  );
}
