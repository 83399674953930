import { FC, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./IconItems.module.scss";
import Icon from "../icon/Icon";
import Link from "next/link";

interface Schema {
  items: any;
}

interface Props {
  blok: Schema;
}

const BlokIconItems: FC<Props> = ({ blok }) => {
  return (
    <div className={styles.IconItems} {...sbEditable(blok)}>
      {blok.items?.map((item: any, index: number) => {
        let url = item.link
          ? item.link.linktype === "story"
            ? "/" + item.link.cached_url
            : item.link.url
          : "#";
        return (
          <Link href={url} passHref key={index}>
            <a>
              <div className={styles.IconItem} {...sbEditable(item)}>
                <Icon className={`icon ${styles.icon}`} name={item.icon} />
                <div>{item.label}</div>
              </div>
            </a>
          </Link>
        );
      })}
    </div>
  );
};

export default BlokIconItems;
