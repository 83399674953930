import { useEffect, useRef, useState } from "react";
import { Button, ButtonPresets } from "../button/Button";
import styles from "./Dropdown.module.scss";
import Icon from "../icon/Icon";

export interface DropdownOption {
  label: string;
  value: string;
  icon?: string;
}

interface Props {
  selected: string;
  disabled?: boolean;
  prefixOptions?: boolean;
  label: string;
  options: DropdownOption[];
  onChange: (value: string) => void;
}

export function Dropdown({
  selected,
  onChange,
  label,
  prefixOptions,
  disabled = false,
  options,
}: Props) {
  const [active, setActive] = useState(selected);
  const [open, setOpen] = useState(false);
  const selectedIndex = options.findIndex((o) => o.value === active);
  const selectedOption =
    selectedIndex === -1
      ? undefined
      : options[options.findIndex((o) => o.value === active)];

  useEffect(() => {
    setActive(selected);
  }, [selected]);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleChange = (newVal: string) => {
    setOpen(false);
    onChange && onChange(newVal);
    setActive(newVal);
  };

  return (
    <div className={styles.dropdown}>
      <Button
        preset={ButtonPresets.dropdown}
        iconRight={!open ? "ExpandMore" : "ExpandLess"}
        label={`${label} ${selectedOption ? selectedOption.label : ""}`}
        onClick={handleClick}
        active={open}
        backgroundTheme="white"
        disabled={disabled}
      />
      <DropdownBox
        options={options}
        prefix={prefixOptions ? label + " " : ""}
        selected={selected}
        open={open}
        onClose={() => setOpen(false)}
        onChange={handleChange}
      />
    </div>
  );
}

export function DropdownBox({
  options,
  onChange,
  prefix,
  selected,
  open,
  onClose,
}: {
  onChange: (newValue: string) => void;
  options: DropdownOption[];
  selected?: string;
  prefix: string;
  open: boolean;
  onClose: () => void;
}) {
  const [active, setActive] = useState<string | undefined>(selected);
  const dropboxRef = useRef<any>(null);

  useEffect(() => {
    setActive(selected);
  }, [selected]);

  useEffect(() => {
    const handleOutSideClick = (e: any) => {
      if (!open || !onClose || !dropboxRef || !dropboxRef.current) return;
      if (!dropboxRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("focusin", handleOutSideClick);

    return () => document.removeEventListener("focusin", handleOutSideClick);
  }, [dropboxRef, open, onClose]);

  return (
    <div className={styles.dropdownBox} data-open={open} ref={dropboxRef}>
      {options.map((option: DropdownOption) => (
        <div
          className={styles.dropdownOption}
          key={`dropdown_option_${option.value}`}
          data-active={active === option.value}
          title={`Click to select ${option.label}`}
          onClick={() => {
            onChange && onChange(option.value);
            setActive(option.value);
          }}
        >
          {option.icon && <Icon name={option.icon} />}
          <span>
            {prefix}
            {option.label}
          </span>
        </div>
      ))}
    </div>
  );
}

export function ShareButton({}: any) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleChange = (newVal: string) => {
    setOpen(false);
    if (newVal === "copy") {
      navigator.clipboard.writeText(window.location.href);
      alert("Copied link to clipboard");
    }
    if (newVal === "linkedin") shareOnLinkedIn();
    if (newVal === "facebook") shareOnFacebook();
    if (newVal === "twitter") shareOnTwitter();
  };

  return (
    <div className={styles.dropdown}>
      <Button
        preset={{
          ...ButtonPresets.dropdown,
          normal: {
            ...ButtonPresets.dropdown,
            filled: true,
          },
        }}
        iconRight={"Share"}
        label={`Share article`}
        onClick={handleClick}
        active={open}
        backgroundTheme="white"
      />

      <DropdownBox
        options={[
          { label: "Copy link", value: "copy", icon: "Link" },
          {
            label: "Share on LinkedIn",
            value: "linkedin",
            icon: "LogoLinkedin",
          },
          {
            label: "Share on Facebook",
            value: "facebook",
            icon: "Facebook",
          },
          { label: "Share on Twitter", value: "twitter", icon: "LogoTwitter" },
        ]}
        selected={undefined}
        open={open}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        prefix={""}
      />
    </div>
  );
}
function shareOnFacebook() {
  const url = encodeURIComponent(window.location.href);
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  window.open(shareUrl, "_blank");
}

function shareOnLinkedIn(url = window.location.href) {
  const shareUrl =
    "https://linkedin.com/sharing/share-offsite" +
    transformObjectToParams({ url, mini: "true" });
  window.open(shareUrl, "_blank");
}

function shareOnTwitter() {
  const url = encodeURIComponent(window.location.href);
  const shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
  window.open(shareUrl, "_blank");
}

export default function transformObjectToParams(object: {
  [key: string]: string | number | undefined | null;
}) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
}
