import { FC } from "react";
import { StoryblokComponent } from "storyblok-js-client";
import DynamicComponent from "../../../DynamicComponent";

interface Blok {
  body: StoryblokComponent<string>[];
}

interface PageProps {
  blok: Blok;
}

const Page: FC<PageProps> = ({ blok }) => (
  <article>
    {blok.body
      ? blok.body.map((blok) => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))
      : null}
  </article>
);

export default Page;
