import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "../../icon/Icon";
/**
 * Primary UI component for user interaction
 */
export const ItemGrid = (props) => {
  const [matchHeight, setMatchHeight] = useState(0);

  const style = {
    grid: {
      gap: props.gap.toString().endsWith("px") ? props.gap : props.gap + "px",
    },
    hover: {},
    item: {
      // width: `calc(100% / ${props.columns})`
    },
  };
  let gridItems = [];

  React.Children.map(props.children, (child, index) => {
    if (child.type) {
      let width = 1;
      if (child.props.stretch) width = child.props.stretch;

      width = `calc(100% / ${props.columns} * ${width} - ${props.gap}px)`;
      if (props.columns == "0") {
        width = "fit-content";
      }
      gridItems.push(
        <div
          className={["lnf-grid-item", null].join("")}
          style={{
            "--grid-gap": props.gap + "px",
            ...style.item,
            width: width,
          }}
          index={index}
          key={index}
        >
          {child}
        </div>
      );
    }
  });

  let large = props.columnsLarge ? props.columnsLarge : props.columns;
  let small = props.columnsSmall ? props.columnsSmall : 1;
  let extraSmall = props.columnsExtraSmall ? props.columnsExtraSmall : 1;

  return (
    <div
      className={[
        "lnf-item-grid",
        props.className,
        "lnf-item-grid-large-" + large,
        "lnf-item-grid-small-" + small,
        "lnf-item-grid-extra-small-" + extraSmall,
      ].join(" ")}
      style={{
        ...style.normal,
      }}
    >
      <div
        className={["top-area", "text-" + props.textAlign].join(" ")}
        style={{
          maxWidth: "900px",
          margin: props.textAlign === "center" ? "auto" : "0",
        }}
      >
        {props.subtitle ? <h6>{props.subtitle}</h6> : null}
        {props.title ? <h2>{props.title}</h2> : null}
        {props.excerpt ? <p>{props.excerpt}</p> : null}
      </div>
      <div
        className={[
          "item-grid",
          "justify-" + props.justify,
          "justify-large-" + props.justifyLarge.toLowerCase(),
          "justify-small-" + props.justifySmall.toLowerCase(),
          "justify-extra-small-" + props.justifyExtraSmall.toLowerCase(),
        ].join(" ")}
        style={style.grid}
      >
        {gridItems}
      </div>
      <div className={"bottom-area"}>
        {props.linkText ? (
          <a href={props.linkUrl} title={props.linkText}>
            <span>{props.linkText}</span> <Icon name={props.linkIcon} />{" "}
          </a>
        ) : null}
      </div>
    </div>
  );
};
