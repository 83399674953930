import { FC, Fragment, useContext } from "react";
import StoryblokContext from "../store/StoryblokContext";
import DynamicComponent from "./DynamicComponent";

const DynamicPageContent: FC = () => {
  const ctx = useContext(StoryblokContext);
  return (
    <Fragment>
      {!ctx?.story || !ctx?.story?.content ? (
        <p>Page not found</p>
      ) : (
        <DynamicComponent blok={ctx.story.content as any} />
      )}
    </Fragment>
  );
};

export default DynamicPageContent;
