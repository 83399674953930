import { StoriesParams, StoryblokResult } from "storyblok-js-client";
import Storyblok from "../../lib/storyblok";
import { IStory } from "./Storyblok.types";

const sharedTypes = ["header", "footer", "calltoaction", "cookiesbanner"];

export interface StoryBlokPageResponseData {
  story: IStory;
  cv: number;
  rels: any[];
  links: any[];
}

export interface StoryblokSharedData {
  headerData: StoryBlokPageResponseData | false;
  footerData: StoryBlokPageResponseData | false;
  calltoactionData: StoryBlokPageResponseData | false;
  cookiesbannerData: StoryBlokPageResponseData | false;
}
export interface StoryblokSharedStories {
  header: IStory | false;
  footer: IStory | false;
  calltoaction: IStory | false;
  cookiesbanner: IStory | false;
}

export interface StoryblokShared {
  data?: StoryblokSharedData;
  stories?: StoryblokSharedStories;
  errors?: string[];
}

export interface StoryblokContextData {
  story: IStory | false;
  storyData: StoryBlokPageResponseData | false;
  shared: StoryblokShared;
  info: {
    request: PageLoadParams;
    fullSlug: string;
    storyType: string;
    storySlug: string;
    isSharedComponent: boolean;
    rootPage: boolean;
    homePage: boolean;
  };
  error: {
    isError: boolean;
    is404: boolean;
    is500: boolean;
    message: string;
  };
}

export interface PageLoadParams {
  request: any;
  requestSlug: any;
  requestSlugName: any;
  storyBlokParams: StoriesParams;
  previewMode: boolean;
  environment: string;
  displayVersion: string;
}
export function getPageLoadParams(props: any): PageLoadParams {
  const { params, preview: previewMode } = props;
  const requestSlug = (
    params?.slug ? (params.slug as string[]).join("/") : "home"
  ).toLowerCase();

  //If Development, use the draft version
  // const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || "production";
  const environment = "development";
  let storyBlokParams: StoriesParams = {
    version: environment === "development" ? "draft" : "published",
  };
  //If viewing in the preview mode, append the draft version
  if (previewMode) {
    storyBlokParams.version = "draft";
    storyBlokParams.cv = Date.now();
  }

  return cleanResponse({
    storyBlokParams,
    requestSlug,
    requestSlugName: requestSlug.split("/").pop(),
    request: props,
    previewMode,
    environment,
    displayVersion: storyBlokParams.version || "published",
  });
}

export async function getStoryBlokPageContext(
  pageRequestProps: any
): Promise<StoryblokContextData> {
  const pageParams: PageLoadParams = getPageLoadParams(pageRequestProps);
  const { requestSlug, requestSlugName, storyBlokParams } = pageParams;
  // console.log(
  //   "Requested Page",
  //   `name: ${pageParams.requestSlugName}`,
  //   `slug: ${pageParams.requestSlug}`,
  //   `preview: ${pageParams.previewMode}`,
  //   `version: ${pageParams.displayVersion}`,
  //   `env: ${pageParams.environment}`,
  //   pageParams.request
  // );

  const context: StoryblokContextData = { ...defaultContext };
  try {
    context.storyData = await getStoryData(requestSlug, storyBlokParams);
    //If page data not found
    if (context.storyData === false && requestSlug !== "404page") {
      context.error.isError = true;
      context.error.is404 = true;
      context.error.message =
        "The requested page was not found - " + requestSlug;
      //Get 404 page instead
      console.warn("The requested page was not found - ", { requestSlug });
      context.storyData = await getStoryData("404page", storyBlokParams);
    }
    if (context.storyData === false) {
      //404 page not found either
      context.error.isError = true;
      context.error.is500 = true;
      context.error.message = "The 404 page was not found - " + requestSlug;
      console.error("The 404 page was not found - ", { requestSlug });
      return cleanContext(context);
    }
    context.story = context.storyData.story || false;
    if (!context.story) {
      context.error.isError = true;
      context.error.is500 = true;
      context.error.message = "The page data was found but not the story";
      console.error("The page data was found but not the story", {
        requestSlug,
        context,
      });
      return cleanContext(context);
    }

    //page info
    context.info.fullSlug = context.story.full_slug || "unknown";
    context.info.storySlug =
      context.story.full_slug?.split("/").pop() || "unknown";
    context.info.storyType = context.story.content?.component || "unknown";
    context.info.rootPage = context.story.is_root || false;
    context.info.homePage = context.story.is_startpage || false;
    context.info.isSharedComponent = context.info.fullSlug.includes("shared");

    //If shared component
    if (context.info.isSharedComponent) {
      //Set my shared data
      context.shared = {
        data: {
          ...defaultContext.shared.data,
          [requestSlugName + "Data"]: context.storyData,
        },
        stories: {
          ...defaultContext.shared.stories,
          [requestSlugName]: context.story,
        },
      } as any;
    } else {
      //Load shared components
      context.shared = await getSharedComponents(storyBlokParams);
    }
  } catch (e) {
    //Just in case something goes wrong
    console.error("Crashed somehow?", { e });
    context.error.isError = true;
    context.error.is500 = true;
    context.error.message = "Crashed somehow?";
  }
  return cleanContext(context);
}

async function getStoryData(
  slug: string,
  sbParams: StoriesParams
): Promise<StoryBlokPageResponseData | false> {
  try {
    const response: StoryblokResult = await Storyblok.get(
      `cdn/stories/${slug}`,
      sbParams
    );
    return response.data;
  } catch (e) {
    console.error("Story data error", { slug, e, sbParams });
    return false;
  }
}

async function getSharedComponents(
  sbParams: StoriesParams
): Promise<StoryblokShared> {
  const StoryblokShared: StoryblokShared | any = {
    data: {},
    stories: {},
    errors: [],
  };
  await Promise.all(
    sharedTypes.map(async (type) => {
      const data = await getSharedComponentData(type, sbParams);
      StoryblokShared.data[type] = data;
      if (data === false) {
        console.error("Error getting shared component: " + type);
        StoryblokShared.errors.push("Error getting shared component: " + type);
        StoryblokShared.stories[type] = false;
      } else {
        StoryblokShared.stories[type] = data?.story || false;
      }
    })
  );

  return StoryblokShared;
}

async function getSharedComponentData(
  slug: string,
  sbParams: StoriesParams
): Promise<any | false> {
  return await getStoryData("shared/" + slug, sbParams);
}

///
// story: IStory | false;
// storyData: any | false;
// shared: StoryblokShared;
// info: {
//   request: PageLoadParams;
//   fullSlug: string;
//   storyType: string;
//   storySlug: string;
//   isSharedComponent: boolean;
//   rootPage: boolean;
//   homePage: boolean;
// };
// error: {
//   isError: boolean;
//   is404: boolean;
//   is500: boolean;
//   message: string;
// };
export const defaultContext: StoryblokContextData = {
  story: false,
  storyData: false,
  shared: {
    data: {
      headerData: false,
      footerData: false,
      calltoactionData: false,
      cookiesbannerData: false,
    },
    stories: {
      header: false,
      footer: false,
      calltoaction: false,
      cookiesbanner: false,
    },
    errors: [],
  },
  info: {
    request: {
      request: {},
      requestSlug: "unknown",
      requestSlugName: "unknown",
      storyBlokParams: {},
      previewMode: false,
      environment: "unknown",
      displayVersion: "unknown",
    },
    fullSlug: "unknown",
    storySlug: "unknown",
    storyType: "unknown",
    isSharedComponent: false,
    rootPage: false,
    homePage: false,
  },
  error: {
    isError: false,
    is404: false,
    is500: false,
    message: "",
  },
};

export function cleanContext(ctx: StoryblokContextData): StoryblokContextData {
  const baseContext = { ...defaultContext };
  const newContext: StoryblokContextData = {
    ...ctx,
  };

  if (!ctx.shared) {
    newContext.shared = baseContext.shared;
    newContext.shared.errors?.push("No shared data found at all");
  } else {
    if (!ctx.shared.data) {
      newContext.shared.data = baseContext.shared.data;
      newContext.shared.errors?.push("No shared data or stories found");
    } else {
      if (!ctx.shared.stories) {
        newContext.shared.stories = baseContext.shared.stories;
        newContext.shared.errors?.push("No shared stories found");
      }
    }
  }
  if (!ctx.info) {
    newContext.info = baseContext.info;
  } else {
    if (!ctx.info.request) {
      newContext.info.request = baseContext.info.request;
    }
  }
  if (!ctx.error) {
    newContext.error = baseContext.error;
  }

  return newContext;
}

export function getStorySettings(story: IStory | false): any {
  if (!story) return {};
  return story?.content || {};
}

export function cleanResponse(obj: any): any {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map((item) => cleanResponse(item));
    } else {
      const fixedObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = cleanResponse(obj[key]);
          if (value !== undefined) {
            fixedObj[key] = value;
          }
        }
      }
      return fixedObj;
    }
  } else if (obj === undefined) {
    return null;
  } else {
    return obj;
  }
}
