import { FC } from "react";
import { StoryblokComponent } from "storyblok-js-client";
import Storyblok from "../../../../lib/storyblok";

interface Blok {
  content: StoryblokComponent<string>[];
}

interface PageProps {
  blok: Blok;
}

const HEADERSECTION: any = {
  body: [
    {
      body: {
        type: "doc",
        content: [
          {
            type: "heading",
            attrs: {
              level: 6,
            },
            content: [
              {
                text: "GET THE LATEST NEWS",
                type: "text",
                marks: [
                  {
                    type: "styled",
                    attrs: {
                      class: "primaryColor",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      fontSize: "16",
      minWidth: "",
      component: "content",
      textAlign: "center",
      textColor: "",
    },
  ],
  hide: false,
  anchor: "blog",
  padding: "160px 20px 140px",
  graphics: [
    {
      asset: {
        id: 6623322,
        alt: "",
        name: "",
        focus: "",
        title: "",
        filename:
          "https://a.storyblok.com/f/181598/848x900/aecc576c37/blob_web.webp",
        copyright: "",
        fieldtype: "asset",
        is_external_url: false,
      },
      style: "position:absolute;",
      insetM: "0 auto auto 0",
      insetS: "-1090px -360px auto auto",
      widthM: "900px",
      widthS: "1200px",
      heightM: "961px",
      heightS: "",
      insetXS: "-1110px -360px auto auto",
      rotateS: "97.34deg",
      component: "Graphic",
      translateL: "-10% -90%",
      translateM: "-30% -90%",
      translateS: "unset",
      translateXL: "20% -90%",
    },
    {
      asset: {
        id: 6623321,
        alt: "",
        name: "",
        focus: "",
        title: "",
        filename:
          "https://a.storyblok.com/f/181598/80x80/166217d369/dots_web.webp",
        copyright: "",
        fieldtype: "asset",
        is_external_url: false,
      },
      style: "position: absolute;",
      insetM: "70% auto auto 80%",
      widthM: "80px",
      widthS: "0",
      heightM: "80px",
      heightS: "0",
      widthXS: "",
      heightXS: "",
      component: "Graphic",
    },
  ],
  maxWidth: "1340px",
  overflow: true,
  component: "section",
  verticalAlign: "center",
  backgroundColor: "#E8FDF5",
  horizontalAlign: "left",
  invertTextColor: false,
  disableAnimation: true,
};

async function getPosts(category: any) {
  let { data, total } = await Storyblok.get(`cdn/stories`, {
    sort_by: "sort_by_date:asc",
    content_type: "blogPost",
    per_page: 3,
    with_tag: category,
  });

  return { posts: data.stories, total };
}

async function getPostsPage(category: any, page: number, firstIDS: string[]) {
  let { data } = await Storyblok.get(`cdn/stories`, {
    sort_by: "sort_by_date:asc",
    content_type: "blogPost",
    per_page: 3,
    page: page,
    with_tag: category,
  });

  return data.stories;
}

const BlogCategory: FC<PageProps> = ({ blok }) => {
  return <></>;
};
// const BlogCategory: FC<PageProps> = ({ blok }) => {
//   const ctx = useContext(StoryblokContext);
//   const cat = ctx.story.content;
//   const title = ctx.story.name;
//   const { category } = cat;
//   const [blogs, setBlogs] = useState([]);
//   const [totalBlogs, setTotalBlogs] = useState(0);
//   const [page, setPage] = useState(1);

//   useEffect(() => {
//     const windowO: any = window;
//     windowO[`allPostsDone_${category}`] = false;
//     getPosts(category).then((data) => {
//       setBlogs(data.posts);
//       setTotalBlogs(data.total);
//     });
//   }, [category]);

//   (() => {
//

//   useEffect(() => {}, [blogs]);

//   const loadMore = () => {
//     const windowO: any = window;
//     if (windowO[`allPostsDone_${category}`]) {
//       return true;
//     }
//     const newPage = page + 1;
//     const existingBlogs: any = blogs.map((blog: any) => blog.id);

//     getPostsPage(category, newPage, existingBlogs).then((data) => {
//       if (data.length == 0) {
//         windowO["allPostsDone"] = true;
//         return;
//       }
//       const newBlogs: any = [...blogs, ...data];

//       setBlogs(newBlogs);
//       setPage(newPage);
//     });
//   };

//   return (
//     <>
//       <BlokSection blok={HEADERSECTION} />
//       <article className={styles.BlogCategory}>
//         <div className={styles.infoSection}>
//           <Link passHref href="/blog/">
//             <a className={styles.back} title={"Go to back to all blogs"}>
//               <Icon name="arrowleft" />
//             </a>
//           </Link>

//           {title && <h1 className={styles.title}>{title}</h1>}
//         </div>
//         <div className={styles.blogs}>
//           {blogs?.map((blog, index) => {
//             return (
//               <div key={`blogPost_${index}`} id={`blogPost_${index}`}>
//                 <BlogCard blok={{ blog: blog }} />
//               </div>
//             );
//           })}
//         </div>
//       </article>
//     </>
//   );
// };

export default BlogCategory;
