export class ThemeProps {
  static getPrimaryButtonProps(props: any) {
    return {
      size: "medium",
      backgroundColor: "var(--button-background)",
      backgroundColorHover: "var(--color-primary-dark)",
      textColor: "var(--button-color)",
      lineHeight: 24,
      ...props,
    };
  }

  static getSecondaryButtonProps(props: any) {
    return {
      size: "medium",
      backgroundColor: "#fff",
      backgroundColorHover: "#F3F3F3",
      textColor: "#0A0A0A",
      borderRadius: "var(--border-radius)",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 24,
      border: "1px solid black",
      ...props,
    };
  }
}

export function cleanVars(input?: string) {
  if (!input) return;
  return input.replace(/@([\w-]+)/g, "var(--$1)");
}
export function cleanColour(colour: string) {
  if (colour?.startsWith("@")) return `var(--${colour.substring(1)})`;
  return colour;
}

const normals = {
  boxSizing: "border-box",
  margin: 0,
  padding: 0,
  border: "none",
  backgroundColor: "transparent",
  fontFamily: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  color: "inherit",
  lineHeight: "inherit",
  textDecoration: "none",
  textAlign: "inherit",
  verticalAlign: "baseline",
  display: "block",
  width: "100%",
  height: "100%",
  position: "relative",
  overflow: "hidden",
};

function getRealName(name: string): string {
  const variableMap: any = {
    bg: "background",
    bgc: "background-color",
    r: "border-radius",
    fs: "font-size",
    fw: "font-weight",
    lh: "line-height",
    m: "margin",
    mt: "margin-top",
    mr: "margin-right",
    mb: "margin-bottom",
    ml: "margin-left",
    p: "padding",
    pt: "padding-top",
    pr: "padding-right",
    pb: "padding-bottom",
    pl: "padding-left",
    align: "text-align",
    w: "width",
    h: "height",
  };
  return variableMap[name] || name;
}

function createVariableName(
  selector: string,
  name: string,
  mobile: boolean
): string {
  return `--${selector}-${mobile ? "mb-" : ""}${name}`;
}

function checkValue(value: string) {
  if (value.startsWith("@")) {
    return `var(--${value.substring(1)})`;
  }
  return value;
}

function makeVars(
  selector: string,
  desktopVars: {
    [key: string]: string;
  },
  mobileVars: {
    [key: string]: string;
  }
): string {
  const desktop = Object.keys(desktopVars)
    .map((name) => {
      return `${createVariableName(selector, name, false)}: ${checkValue(
        desktopVars[name]
      )};`;
    })
    .join("\n");

  const mobile = Object.keys(mobileVars)
    .map((name) => {
      return `${createVariableName(selector, name, true)}: ${checkValue(
        mobileVars[name]
      )};`;
    })
    .join("\n");

  return `${desktop}\n${mobile}`;
}

function makeFields(
  selector: string,
  desktopVars: {
    [key: string]: string;
  },
  mobileVars: {
    [key: string]: string;
  }
): { desktop: string; mobile: string } {
  const desktopCreated: any = normals;
  const mobileCreated: any = normals;

  Object.keys(desktopVars).forEach((name) => {
    const realName = getRealName(name);
    desktopCreated[realName] = `var(--${createVariableName(
      selector,
      name,
      false
    )})`;
  });
  Object.keys(mobileVars).forEach((name) => {
    const realName = getRealName(name);
    mobileCreated[realName] = `var(--${createVariableName(
      selector,
      name,
      false
    )})`;
  });

  return {
    desktop: Object.keys(desktopCreated)
      .map((name) => `${name}: ${desktopCreated[name]};`)
      .join("\n"),
    mobile: Object.keys(mobileCreated)
      .map((name) => `${name}: ${mobileCreated[name]};`)
      .join("\n"),
  };
}

function makeElement(
  selector: string,
  desktopVars: {
    [key: string]: string;
  },
  mobileVars: {
    [key: string]: string;
  }
): string {
  const variables = makeVars(selector, desktopVars, mobileVars);
  const fields = makeFields(selector, desktopVars, mobileVars);
  return `
    :root{
      ${variables}
    }

    ${selector}{
      ${fields.desktop}
      @media only screen and (max-width: 600px) {
        ${fields.mobile}
      }

    }

  `;
}

makeElement(
  "h3",
  {
    fs: "@fs-small",
    weight: "900",
    color: "red",
  },
  {
    fs: "@fs-tiny",
    weight: "700",
  }
);

//should look like %(primary, #000, #fff)
function createVariable(
  name: string,
  value: string,
  mobileValue?: string
): string {
  return `--${name}: ${value};${
    mobileValue ? `\n--mb-${name}: ${mobileValue};` : ""
  }`;
}
function modifyColourWeight(color: string, weight: number = 100) {
  //TODO: implement this for me, changing the weight of a colour, the colour provided is considered 500 weight
  return "";
}
function findContrastColour(color: string) {
  //TODO: implement this for me, find a colour that works well for text on it
  return "";
}

//weights 100 - 900
//should look like +(primary, #000)
function createColorVariable(name: string, value: string) {
  let vars = "";
  vars += createVariable(name, value) + "\n";
  vars += createVariable(name, value) + "\n";
  for (let i = 100; i <= 900; i += 100) {
    if (i === 500) continue;
    const weightedColour = modifyColourWeight(value, i);
    vars + `${createVariable(`${name}-${i}`, weightedColour)}\n`;
    vars +
      `${createVariable(
        `${name}-contrast`,
        findContrastColour(weightedColour)
      )}\n`;
  }
  return vars;
}

//EG:
// %("font", "Roboto");
// +("primary", "#4B5FEB");

//make funcs for adding css props, eg center
