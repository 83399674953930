import React, { useState, useRef, useEffect } from "react";

export const InputText = (props) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);
  const [filled, setFilled] = useState(false);
  const input = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const resetTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeoutRef.current);
      clearTimeout(resetTimeoutRef.current);
    };
  }, []);

  const updateInputValue = (e) => {
    e.target.value.length > 0 ? setFilled(true) : setFilled(false);

    props.onChange && props.onChange(e.target.value);
  };

  const failed = props.failed && !filled ? props.failed.failed : false;

  const handleMouseEnter = () => {
    clearTimeout(resetTimeoutRef.current);
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
    resetTimeoutRef.current = setTimeout(() => {
      setHover(false);
    }, 1000); // Delay before resetting the hover state (adjust as needed)
  };

  const handleClick = () => {
    input.current.focus();
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        className={[
          "inputWrapper",
          filled ? "filled" : null,
          failed ? "failed" : null,
          focus ? "focus" : null,
        ].join(" ")}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        data-disabled={props.disabled}
      >
        <input
          aria-label={props.label}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          type={props.type ? props.type : "text"}
          className="input input-text"
          ref={input}
          data-field={props.slug}
          onChange={updateInputValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={props.slug}
          required={props.required}
          disabled={props.disabled}
        />
        <span className="label">
          <b>{props.label}</b>
          {props.required ? " (required)" : ""}
        </span>
        {failed ? (
          <div className={"input-error"}>{props.failed.error}</div>
        ) : null}
      </div>
    </div>
  );
};
