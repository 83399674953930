import React, { useEffect } from "react";
import Icon from "../../../icon/Icon";

export const InputCheckBox = ({
  disabled,
  label,
  slug,
  required,
  radio,
  failed,
  checked: propchecked,
  onChange,
}: {
  disabled?: boolean;
  label: string;
  slug: string;
  required?: boolean;
  failed?: any;
  checked?: boolean;
  radio?: boolean;
  onChange?: (checked: boolean) => void;
}) => {
  const [checked, setChecked] = React.useState(propchecked);
  const failedF = failed ? failed.failed : false;

  useEffect(() => {
    setChecked(propchecked);
  }, [propchecked]);

  return (
    <div>
      <div
        className={["inputWrapperCheckbox"].join(" ")}
        data-disabled={disabled}
        data-checked={checked}
        data-radio={radio}
        onClick={() => {
          if (radio) {
            setChecked(checked);
          } else {
            setChecked(!checked);
          }

          onChange && onChange(!checked);
        }}
      >
        <input
          aria-label={label}
          type={radio ? "checkbox" : "radio"}
          className="input-checkbox"
          data-field={slug}
          name={slug}
          required={required}
          disabled={disabled}
          checked={checked}
          hidden
          value={checked ? "true" : "false"}
        />
        <div className="check">
          <Icon name={!radio ? "Check" : "Circle"} />
        </div>
        <label htmlFor={slug} className="label">
          {label}
          {required ? " (required)" : ""}
        </label>
        {failedF ? <div className={"input-error"}>{failed.error}</div> : null}
      </div>
    </div>
  );
};
