import { FC, useCallback, useContext, useEffect, useState } from "react";
import StoryblokContext from "../../../../store/StoryblokContext";
import styles from "./Footer.module.scss";
import Link from "next/link";
import Icon from "../icon/Icon";
import Content from "../content/Content";
import { getStorySettings } from "../../StoryblokData";

const Footer: FC = () => {
  const ctx = useContext(StoryblokContext);
  if (!ctx?.shared?.stories?.footer) return null;
  const { content, phone, companyName, leftLinks, rightLinks } =
    getStorySettings(ctx?.shared?.stories?.footer);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.content}>
          <p>
            <Content blok={{ body: content }} />
          </p>
          <p>
            <Link href={`tel:${phone || "123"}`}>{phone || "No Number"}</Link>
          </p>
        </div>
        <div className={styles.links}>
          <div className={styles.left}>
            {leftLinks &&
              leftLinks.map((link: any, index: number) => {
                return (
                  <div className={styles.link} key={`leftLink_${index}`}>
                    <Link
                      href={URLfromLink(link.url)}
                      title={`Go to ${link.title}`}
                      target={link?.url?.target}
                    >
                      {link.title}
                    </Link>
                  </div>
                );
              })}
          </div>
          <div className={styles.right}>
            {rightLinks &&
              rightLinks.map((link: any, index: number) => {
                return (
                  <div className={styles.link} key={`rightLink_${index}`}>
                    <Link
                      href={URLfromLink(link.url)}
                      title={`Go to ${link.title}`}
                      target={link?.url?.target}
                    >
                      {link.title}
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copy}>
            © {new Date().getFullYear()} {companyName}
          </div>
          <div className={styles.rights}>
            Developed by{" "}
            <Link target={"_blank"} href={"https://mdt.id.au"}>
              mdt
            </Link>
          </div>
        </div>
        <div
          className={styles.btt}
          title={"Scroll to top"}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <Icon name={"north"} />
        </div>
      </div>
    </footer>
  );
};

export function URLfromLink(link: any) {
  if (!link) return "";
  let url =
    link?.linktype === "story"
      ? link?.cached_url
        ? "/" + link?.cached_url
        : ""
      : link?.url
      ? link?.url
      : "";

  // if (url === "/home") return "/";
  if (url === "/home") return "/";
  return url;
}
export default Footer;
