import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./FooterContent.module.scss";
import Link from "next/link";
import Richtext from "../content/RichText";

interface Schema {
  logo: any;
  content: any;
  links: any;
  address: string;
  copy: string;
}

interface Props {
  blok: Schema;
}

const BlokFooterContent: FC<Props> = ({ blok }) => {
  const { logo, content, links, address, copy } = blok;

  return (
    <div {...sbEditable(blok)} className={styles.FooterContent}>
      <div className={styles.left}>
        <Link href="/" passHref scroll={true}>
          <a>
            <img
              className={styles.logo}
              src={logo?.filename}
              alt={"Site logo"}
              width="152px"
              height="68px"
            />
          </a>
        </Link>
      </div>
      <div className={styles.right}>
        {content && (
          <div className={styles.content}>
            <Richtext content={content} />
          </div>
        )}
        {links && (
          <div className={styles.links}>
            {links?.map((link: any, index: number) => {
              const url = link.url
                ? link.url.linktype === "story"
                  ? "/" + link.url.cached_url
                  : link.url.url
                : "#";
              return (
                <Link
                  href={url}
                  passHref
                  key={index}
                  scroll={true}
                  target="_blank"
                >
                  {link.label}
                </Link>
              );
            })}
          </div>
        )}
        <div>
          {address && <div className={styles.address}>{address}</div>}
          {copy && <div className={styles.copy}>{copy}</div>}
        </div>
      </div>
    </div>
  );
};

export default BlokFooterContent;
