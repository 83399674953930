import { useEffect, useRef, useState } from "react";

const useScreenWidth = (): number | undefined => {
  const [screenWidth, setScreenWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenWidth;
};

export default useScreenWidth;

const useElementWidth = (
  elementRef: React.RefObject<HTMLElement | any>
): [number] => {
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const { current: element } = elementRef;
      if (element) {
        setElementWidth(element.offsetWidth);
      }
    };

    handleResize(); // Initial measurement

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [elementWidth];
};

export { useElementWidth };
