import { FC } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
type Column = {
  _uid: string;
  value: string;
  component: string;
  _editable: string;
};

type Row = {
  _uid: string;
  body: Column[];
  component: string;
  _editable: string;
};

type Table = {
  thead: Column[];
  tbody: Row[];
};

interface Schema {
  Content: Table;
  evenColumns?: boolean;
}

interface Props {
  blok: Schema;
}

const BlokTable: FC<Props> = ({ blok }) => {
  const { evenColumns = true, Content: tableData } = blok;
  return (
    <table
      {...sbEditable(blok)}
      style={{
        tableLayout: evenColumns ? "fixed" : "auto",
        width: "100%",
        textAlign: "left",
        borderCollapse: "collapse",
      }}
    >
      {tableData.thead ? (
        <thead>
          <tr
            style={{
              borderBottom: "2px solid #12131A",
            }}
          >
            {tableData.thead?.map((col) => (
              <th
                key={col._uid}
                style={{
                  color: "#252833",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "200%",
                  wordWrap: "break-word", // ensure that text doesn't overflow
                }}
              >
                {col.value.split("\n").map((item, key, arr) => {
                  return (
                    <span key={key}>
                      {item}
                      {key + 1 !== arr.length ? <br /> : undefined}
                    </span>
                  );
                })}
              </th>
            ))}
          </tr>
        </thead>
      ) : null}
      {tableData.tbody ? (
        <tbody>
          {tableData.tbody?.map((row) => (
            <tr key={row._uid} style={{ borderBottom: "1px solid #9A9EB2" }}>
              {row.body.map((col) => (
                <td
                  key={col._uid}
                  style={{
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "200%",
                    wordWrap: "break-word", // ensure that text doesn't overflow
                  }}
                >
                  {col.value.split("\n").map((item, key, arr) => {
                    return (
                      <span key={key}>
                        {item}
                        {key + 1 !== arr.length ? <br /> : undefined}
                      </span>
                    );
                  })}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ) : null}
    </table>
  );
};

export default BlokTable;
