import { ComponentType, FC, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { URLfromLink } from "../footerBlok/FooterBlok";
import { sbEditable } from "@storyblok/storyblok-editable";

interface Schema {
  title: string;
  className?: string;
  url?: any;
  newTab?: boolean;
}

interface Props {
  blok: Schema;
}

const BlokLink: FC<Props> = ({ blok }) => {
  let url = URLfromLink(blok.url);
  return (
    <Link
      className={blok.className + " link"}
      href={url}
      {...sbEditable(blok)}
      target={blok.newTab ? "_blank" : "_self"}
    >
      {blok.title}
    </Link>
  );
};

export default BlokLink;
