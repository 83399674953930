import { createContext, FC, useState } from "react";
import { useStoryblok } from "../lib/storyblok";
import {
  defaultContext,
  StoryblokContextData,
} from "../components/storyblok/StoryblokData";

interface IContextProviderProps {
  contextData?: StoryblokContextData;
  children: React.ReactNode;
}
const StoryblokContext = createContext<StoryblokContextData>(defaultContext);

export const StoryblokContextProvider: FC<IContextProviderProps> = (props) => {
  const [enableBridge] = useState(true);
  const wrapStory = useStoryblok(props.contextData?.story, enableBridge);
  const dataValue: any = { ...props.contextData, story: wrapStory };
  return (
    <StoryblokContext.Provider value={dataValue}>
      {props.children}
    </StoryblokContext.Provider>
  );
};

export default StoryblokContext;
