import { FC, useContext } from "react";
import StoryblokContext from "../../store/StoryblokContext";
import { getStorySettings } from "../storyblok/StoryblokData";
import DynamicComponent from "../DynamicComponent";

const Footer: FC = () => {
  const ctx = useContext(StoryblokContext);
  if (!ctx?.shared?.stories?.footer) return null;
  const { bloks } = getStorySettings(ctx?.shared?.stories?.footer);
  return (
    <footer>
      {bloks?.map((childBlok: any, index: number) => (
        <DynamicComponent blok={childBlok} key={index} />
      ))}
    </footer>
  );
};

export function URLfromLink(link: any) {
  let url =
    link.linktype === "story"
      ? link.cached_url
        ? "/" + link.cached_url
        : ""
      : link.url
      ? link.url
      : "";

  // if (url === "/home") return "/";
  if (url === "/home") return "/";
  return url;
}
export default Footer;
