export const BUSSINESS_NAME = "SuperCo";
export const BUSSINESS_TAGLINE = "Leading SMSF specialist in Canberra";
export const BUSSINESS_TITLE = `${BUSSINESS_NAME}`;
export const BUSSINESS_DESCRIPTION =
  "Superco specialises in self-managed superannuation fund (SMSF) administration, providing personalised guidance to ensure compliance and growth of your retirement savings. Talk to our experts today.";
export const BUSSINESS_KEYWORDS = [
  "SMSF",
  "Superannuation",
  "Compliance",
  "Retirement",
  "Savings",
];

export const BUSSINESS_URL = "https://www.superco.com.au";
export const BUSSINESS_COLOUR = "#007aff";

export const META = {
  title: BUSSINESS_TITLE,

  ogTitle: { property: "og:title", content: BUSSINESS_TITLE },
  ogUrl: { property: "og:url", content: BUSSINESS_URL },
  ogDescription: { property: "og:description", content: BUSSINESS_DESCRIPTION },
  description: { name: "description", content: BUSSINESS_DESCRIPTION },
  keywords: { name: "keywords", content: BUSSINESS_KEYWORDS.join(", ") },
  ogType: { property: "og:type", content: "website" },
  ogImage: { property: "og:image", content: "/Favicon-32.png" },

  themeColor: BUSSINESS_COLOUR,
  appleMobileWebAppStatusBarColor: BUSSINESS_COLOUR,

  favicon16: {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/Favicon-16.png",
  },
  favicon32: {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/Favicon-32.png",
  },
  favicon192: {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    href: "/Favicon-192.png",
  },
  favicon512: {
    rel: "icon",
    type: "image/png",
    sizes: "512x512",
    href: "/Favicon-512.png",
  },
  faviconIco: { rel: "icon", href: "/Favicon.ico" },
  appleTouchIcon: {
    rel: "apple-touch-icon",
    type: "image/png",
    href: "/Favicon-256.png",
  },

  manifest: { href: "/manifest.json" },
};

interface Shortcodes {
  [key: string]: string | undefined;
}

interface Values {
  [key: string]: string;
}

export function replaceShortcodes(
  values: Values,
  shortcodes: Shortcodes
): Values {
  const regex = /\{(.*?)\}/g; // Regular expression to match shortcodes

  // Iterate over the values and replace shortcodes
  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (typeof value === "string") {
      values[key] = value.replace(regex, (match, shortcode) => {
        // Look up the shortcode in the shortcodes object
        const replacement = shortcodes[shortcode.trim()];
        return replacement !== undefined ? replacement : match;
      });
    }
  });

  return values;
}
