import { FC, useEffect, useRef } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./ItemCard.module.scss";
import { useMediaQuery } from "usehooks-ts";

interface Schema {
  image: any;
  imageWidth: string;
  imageHeight: string;
  imageRadius: string;
  imageRatio: string;
  imageFit: string;
  align: string;
  title: string;
  excerpt: string;
  delay: number;
}

interface Props {
  blok: Schema;
}

const BlokItemCard: FC<Props> = ({ blok }) => {
  const {
    image,
    imageWidth,
    imageHeight,
    imageRadius,
    align,
    title,
    excerpt,
    imageFit,
    imageRatio,
    delay,
  } = blok;
  const comp = useRef(null);
  const matches = useMediaQuery("(max-width: 1000px)");

  const imageStyles: any = {
    objectFit: imageFit || "contain",
    borderRadius: imageRadius || "0px",
    width: !imageRatio ? imageWidth : "100%",
    height: !imageRatio ? imageHeight : "auto",
    aspectRatio: imageRatio || "unset",
  };

  return (
    <div
      {...sbEditable(blok)}
      ref={comp}
      className={styles.ItemCard}
      data-align={align}
    >
      {image && (
        <img
          className={styles.image}
          alt={title}
          src={image.filename}
          style={imageStyles}
        />
      )}
      <h4>{title}</h4>
      <p>{excerpt}</p>
    </div>
  );
};

export default BlokItemCard;
