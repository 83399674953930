import { FC, useEffect, useRef, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import styles from "./GoogleMapStyles.json";

interface Schema {
  lat: string;
  lng: string;
  height: string;
  zoom: number;
  width: string;
  key: string;
}

interface Props {
  blok: Schema;
}

const BlokMap: FC<Props> = ({ blok }) => {
  let { width, height, key, lat, lng, zoom } = blok;

  const location = { lat: parseFloat(lat), lng: parseFloat(lng) };
  lat = Number(lat).toFixed(3);
  lng = Number(lng).toFixed(3);
  // zoom = 12;
  const styles = {
    width,
    height,
  };

  return (
    <div style={styles}>
      <MapComp mapKey={key} map={{ location, zoom }} />
    </div>
  );
};
export default BlokMap;

declare global {
  interface Window {
    initMap: () => void;
  }
}

const mapStyles: any = styles || [];

function MapComp(props: { map: any; mapKey: string }) {
  const { map, mapKey: key } = props;

  const GoogleMapComponentWithMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={Number(map.zoom)}
        zoom={Number(map.zoom)}
        defaultCenter={map.location}
        defaultOptions={{
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          keyboardShortcuts: false, // disable keyboard shortcuts
          // scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          styles: mapStyles, // change default map styles
        }}
      >
        <Marker
          icon={{
            url: "/Union.svg",
          }}
          position={map.location}
        />
      </GoogleMap>
    ))
  );
  return (
    <GoogleMapComponentWithMarker
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${key}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}
// Google Map component
