import { NextPage } from "next";
import { StoryblokContextProvider } from "../store/StoryblokContext";
import DynamicPageContent from "./DynamicPageContent";
import Layout from "./layout/Layout";
import { StoryblokContextData } from "./storyblok/StoryblokData";

export const DynamicPageWrap: NextPage<StoryblokContextData> = (
  contextData: StoryblokContextData
) => {
  return (
    <StoryblokContextProvider {...contextData}>
      <Layout>
        <DynamicPageContent />
      </Layout>
    </StoryblokContextProvider>
  );
};
