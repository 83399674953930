import { ComponentType, FC, useEffect, useState } from "react";
import Icon from "./Icon";
import Link from "next/link";
import { useRouter } from "next/router";

interface Schema {
  name: string;
  colour?: string;
  className?: string;
  link?: any;
}

interface Props {
  blok: Schema;
}

const BlokIcon: FC<Props> = (props) => {
  if (props.blok.link) {
    let url = props.blok.link
      ? props.blok.link.linktype === "story"
        ? "/" + props.blok.link.cached_url
        : props.blok.link.url
      : "#";
    return (
      <>
        <SmoothScrollLink href={url}>
          <Icon
            className={props.blok.className}
            name={props.blok.name}
            colour={props.blok.colour}
          />
        </SmoothScrollLink>
      </>
    );
  }
  return (
    <Icon
      className={props.blok.className}
      name={props.blok.name}
      colour={props.blok.colour}
    />
  );
};

const SmoothScrollLink = ({ href, children }: any) => {
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsMounted(true);
    // console.log("smooth scroll to ", { href });

    // if (isMounted && router.asPath === href) {
    //   console.log("smooth scroll to2 ", { href });

    //   const target = document.getElementById(href.replace("#", ""));

    //   target?.scrollIntoView({ behavior: "smooth" });
    // }
  }, [isMounted, href, router.asPath]);

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        const target = document.getElementById(href.replace("#", ""));

        target?.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <div>{children}</div>
    </div>
  );
};

export default BlokIcon;
