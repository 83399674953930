import { FC, useEffect, useRef, useState } from "react";
import { StoryblokComponent } from "storyblok-js-client";
import Storyblok from "../../../../lib/storyblok";
import { IBlog } from "../blogCard/BlogCard";
import styles from "./AllBlogs.module.scss";

import moment from "moment";
import { ToggleButtons } from "../toggleButtons/ToggleButton";
import { Dropdown, DropdownOption } from "../dropdown/Dropdown";
import { Pagination } from "../pagination/Pagination";
import Columns from "../columns/Columns";
import { useRouter } from "next/router";
import { useMediaQuery } from "usehooks-ts";
import { Button, ButtonPresets } from "../button/Button";
import { InputCheckBox } from "../form/inputs/checkbox/InputCheckBox";
import { emptyString } from "../content/Content";

interface Blok {
  content: StoryblokComponent<string>[];
}

interface PageProps {
  blok: Blok;
}

const max_items = 100;
const per_page = 9;
const sort_options: DropdownOption[] = [
  {
    label: "new to old",
    value: "desc",
  },
  { label: "old to new", value: "asc" },
];

async function getPosts(
  page: number = 0
): Promise<{ posts: IBlog[]; total: number; pages: number }> {
  console.log("test", {
    blogs: await Storyblok.get(`cdn/stories`, {
      version: "published",
    }),
  });
  let stories = [];
  let { data, total } = await Storyblok.get(`cdn/stories`, {
    sort_by: "sort_by_date:asc",
    content_type: "blogPage",
    version: "published",
    per_page: max_items,
  });
  const pageCount = Math.ceil(total / max_items);

  stories.push(...data.stories);
  if (pageCount > page + 1) {
    const more = await getPosts(page + 1);
    // console.log("getting more", { data, total, stories, pageCount });

    stories.push(...more.posts);
  }

  // console.log("Got posts", { data, total, stories, pageCount });

  return { posts: stories, total, pages: pageCount };
}

function getPageOfPosts(
  posts: any[],
  page: any = 0,
  per_page: number = 9
): any[] {
  return [...posts].slice(page * per_page, (page + 1) * per_page);
}

function postsFromOptions(
  allPosts: IBlog[],
  page: number,
  selectedFilters: string[],
  sort_by: string,
  categories: string[] = []
) {
  selectedFilters = selectedFilters.filter(
    (s) => !emptyString(s) && categories.indexOf(s) !== -1
  );
  let posts = [...allPosts];
  posts = [...posts].sort((a: IBlog, b: IBlog) => {
    const dateA = a.content?.date
      ? moment(a.content?.date)
      : moment(a.sort_by_date);
    const dateB = b.content?.date
      ? moment(b.content?.date)
      : moment(b.sort_by_date);
    if (sort_by === "desc") {
      return dateB.diff(dateA);
    }
    return dateA.diff(dateB);
  });

  posts = posts.filter((post) => {
    if (selectedFilters.length === 0) return true;
    if (selectedFilters.includes(post?.content?.category)) return true;
    return post?.tag_list?.some((tag: string) => selectedFilters.includes(tag));
  });

  posts = getPageOfPosts([...posts], page, per_page);

  return posts;
}

const BlogAll: FC<PageProps> = ({}) => {
  const { push, query } = useRouter();
  const mobile = useMediaQuery("(max-width: 1000px)");

  const [mobileFiltersOpen, setFiltersOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [visibleBlogs, setVisibleBlogs] = useState<IBlog[]>([]);
  const [page, setPage] = useState(0);
  const [selectedFilters, setFilters] = useState<string[]>([]);
  const [sort_by, setSort] = useState<string>("desc");
  const blogsRef = useRef<any>(null);

  useEffect(() => {
    getPosts().then((data) => {
      setBlogs(data.posts);
      const categories: string[] = [];
      data.posts.forEach((post) => {
        if (!post?.content?.category) return;
        const cat = post?.content?.category;
        if (categories.indexOf(cat) !== -1 || !cat) return;
        categories.push(cat);
      });

      setCategories(categories);
    });
  }, []);

  useEffect(() => {
    setSort(query.sort?.toString() || "desc");
    setFilters(query.filters?.toString().split(",") || []);
    setPage(query.page ? Number(query.page) : 0);
  }, [query]);

  useEffect(() => {
    const main = document.querySelector("main");
    if (!main) return;
    main?.style.setProperty("position", "relative");

    if (mobileFiltersOpen && mobile) {
      main?.style.setProperty("z-index", "1001");
    } else {
      main?.style.setProperty("z-index", "1");
    }
  }, [mobile, mobileFiltersOpen]);

  useEffect(() => {
    const newPosts = postsFromOptions(
      [...blogs],
      page,
      selectedFilters,
      sort_by,
      categories
    );

    setVisibleBlogs(newPosts);
  }, [page, selectedFilters, sort_by, blogs, categories]);

  const handlePageChange = (page: number) => {
    // setPage(page);

    push({ query: { ...query, page } }, undefined, {
      shallow: true,
    });
    setTimeout(() => {
      blogsRef?.current?.scrollIntoView({
        block: "start",
        // inline: "nearest",
        behavior: "smooth",
      });
    }, 50);
  };

  const handleFilterChange = (selected: string[]) => {
    // setFilters(selected);
    // setPage(0);
    selected = selected.filter(
      (s) => !emptyString(s) && categories.indexOf(s) !== -1
    );
    push(
      { query: { ...query, filters: selected.join(","), page: 0 } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const handleApplyFilters = (filters: string[], sort: string) => {
    filters = filters.filter(
      (s) => !emptyString(s) && categories.indexOf(s) !== -1
    );
    push(
      { query: { ...query, filters: filters.join(","), sort: sort, page: 0 } },
      undefined,
      {
        shallow: true,
      }
    );
    setFiltersOpen(false);
  };

  const handleSortChange = (sort: string) => {
    // setSort(sort);
    // setPage(0);
    push({ query: { ...query, sort: sort, page: 0 } }, undefined, {
      shallow: true,
    });
  };

  return (
    <>
      <article className={styles.BlogAll} id="blogs">
        <div ref={blogsRef}></div>
        <div className={styles.toolbar + " hideMobile"}>
          <div>
            <ToggleButtons
              selected={selectedFilters}
              options={categories}
              onChange={handleFilterChange}
            />
          </div>
          <div>
            <Dropdown
              selected={sort_by}
              options={sort_options}
              label="Sort by"
              prefixOptions
              onChange={handleSortChange}
            />
          </div>
        </div>

        <div className={styles.blogs}>
          <Columns
            key={`blogs_${JSON.stringify(visibleBlogs)}`}
            blok={{
              gap: "40px",
              wrap: "wrap",
              columns: 3,
              columnsLarge: 3,
              columnsSmall: 1,
              columnsExtraSmall: 1,
              overflow: true,
              className: "blogsGrid",
              verticalAlign: "stretch",
              horizontalAlign: "left",
              items: visibleBlogs?.map((blog) => {
                return {
                  fullBlog: blog,
                  component: "blogCard",
                };
              }),
            }}
          />
        </div>
        <div className={styles.pagination}>
          <Pagination
            page={page}
            totalPages={Math.ceil(blogs.length / per_page)}
            onChange={handlePageChange}
            range={5}
          />
        </div>

        {mobile && (
          <ToolbarMobile
            selectedFilters={selectedFilters}
            categories={categories}
            sort_by={sort_by}
            open={mobileFiltersOpen}
            onClose={() => {
              setFiltersOpen(false);
            }}
            onApply={handleApplyFilters}
          />
        )}

        {mobile && (
          <Button
            backgroundTheme="white"
            className={styles.filterButton}
            onClick={() => {
              setFiltersOpen(!mobileFiltersOpen);
            }}
            preset={{
              ...ButtonPresets.primary,
              normal: {
                ...ButtonPresets.primary.normal,
                circle: true,
              },
            }}
            iconLeft="Tune"
          />
        )}
      </article>
    </>
  );
};

function ToolbarMobile({
  selectedFilters: f,
  categories,
  sort_by: s,
  open = false,
  onApply,
  onClose,
}: any) {
  const [selectedFilters, setFilters] = useState<string[]>(f);
  const [sort_by, setSort] = useState<string>(s);

  useEffect(() => {
    setFilters(f);
    setSort(s);
  }, [f, s, open]);

  const handleFilterChange = (selected: string[]) => {
    setFilters(selected || []);
  };

  const handleSortChange = (sort: string) => {
    setSort(sort || "desc");
  };

  return (
    <div
      className={styles.toolbarMobile + " hideDesktop"}
      data-open={open}
      data-theme="white"
    >
      <div className={styles.top}>
        <Button
          onClick={onClose}
          label="Close"
          noText
          iconLeft="Close"
          noOutline
          circle
        />
      </div>
      <div
        className={styles.content}
        style={{ gap: 40, display: "flex", flexDirection: "column" }}
      >
        <h2 className="h1">Filter articles</h2>
        <div style={{ gap: 16, display: "flex", flexDirection: "column" }}>
          <h3>Topic</h3>
          <ToggleButtons
            selected={selectedFilters}
            options={categories}
            onChange={handleFilterChange}
          />
        </div>
        <div style={{ gap: 16, display: "flex", flexDirection: "column" }}>
          <h3>Sort by</h3>
          <div style={{ gap: 16, display: "flex", flexDirection: "column" }}>
            {sort_options.map((option) => {
              return (
                <InputCheckBox
                  radio
                  key={"sort_" + option.value}
                  slug={option.value}
                  label={option.label}
                  checked={sort_by === option.value}
                  onChange={(checked: boolean) => {
                    if (checked) {
                      handleSortChange(option.value);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div
        className={styles.bottom}
        style={{ gap: 12, display: "flex", flexDirection: "column" }}
      >
        <Button
          preset={ButtonPresets.primary}
          onClick={() => {
            onApply(selectedFilters, sort_by);
          }}
          label="Apply filters"
          fullWidthMobile
        />
        <Button
          preset={ButtonPresets.secondary}
          onClick={() => {
            onApply([], "desc");
          }}
          label="Clear filters"
          fullWidthMobile
        />
      </div>
    </div>
  );
}

export default BlogAll;
