import moment from "moment";
import { FC, useEffect, useState } from "react";
import { URLfromLink } from "../footerBlok/FooterBlok";
import { sbEditable } from "@storyblok/storyblok-editable";
import Storyblok from "../../../../lib/storyblok";

export interface IBlog {
  title: string;
  content: {
    excerpt: string;
    date: string;
    thumbnail: any;
    category: any;
    content: any;
  };
  published_at?: string;
  sort_by_date?: string;
  tag_list?: any;
}
interface Blog {
  fullBlog?: IBlog;
  blog?: string;
  label?: string;
  title?: string;
  url?: string;
  excerpt?: string;
  timeToRead?: string;
  date?: string;
  thumbnail?: string;

  theme?: string;
  labelCategory?: boolean;
}
interface Blok extends Blog {
  link?: any;
}

interface PageProps {
  blok: Blok;
}

const BlogCard: FC<PageProps> = ({ blok }) => {
  const [storyData, setStoryData] = useState<any>(blok.fullBlog);
  let {
    title,
    url,
    excerpt,
    timeToRead,
    date,
    theme,
    link,
    thumbnail,
    label,
    labelCategory = true,
    blog,
  } = blok;

  useEffect(() => {
    if (blok.fullBlog) return;
    if (!blog) return;
    getStory(blog).then((data) => {
      setStoryData(data);
    });
  }, [blog, blok.fullBlog]);
  if (link) {
    url = URLfromLink(link);
  }

  if (storyData) {
    const { content } = storyData;
    title = content.title;
    excerpt = content.excerpt;
    thumbnail = content.thumbnail;
    date = content.date;
    timeToRead = content.timeToRead;
    label = content.category;
    url = "/" + storyData.full_slug;
  }

  return (
    <div
      key={`blogCard_${title}`}
      className={"BlogCard"}
      {...sbEditable(blok)}
      data-theme={theme}
    >
      {thumbnail && (
        <div className={"BlogImage"}>
          <img src={thumbnail} alt={title + " Thumbnail"} />
        </div>
      )}
      <div className={"BlogContent"}>
        <div>
          {label && (
            <a
              className={"label"}
              href={labelCategory ? "/blog/" + label?.toLowerCase() : undefined}
              title={`See more in ${label}`}
            >
              {label}
            </a>
          )}
          {title && <h3 className={"title"}>{title}</h3>}
        </div>

        <p>{excerpt}</p>
        <div className={"info"}>
          {date && (
            <div className={"date"}>{moment(date).format("MMMM YYYY")}</div>
          )}
          {date && timeToRead && "•"}
          {timeToRead && (
            <div className={"timeToRead"}>{timeToRead} min read</div>
          )}
        </div>
        {url && (
          <a
            className={"bigClicker"}
            href={url}
            title={`Read more about ${title}`}
          ></a>
        )}
      </div>
    </div>
  );
};

async function getStory(id: string) {
  return await Storyblok.get(`cdn/stories/${id}`, { find_by: "uuid" }).then(
    (res) => res.data.story
  );
}

export default BlogCard;
