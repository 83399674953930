import { FC, useEffect, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import styles from "./Steps.module.scss";

interface Schema {
  steps: any;
  auto: boolean;
  autoTime: number;
}

interface Props {
  blok: Schema;
}

const BlokSteps: FC<Props> = ({ blok }) => {
  const { steps, auto, autoTime } = blok;

  const [stepIndex, setStepIndex] = useState(0);
  const [currentTimeOut, setCurrentTimeOut] = useState<any>(null);

  const currentItem =
    steps && steps.length > stepIndex ? steps[stepIndex] : null;

  useEffect(() => {
    handleStepChange();

    return () => {
      clearTimeout(currentTimeOut);
    };
  }, []);

  useEffect(() => {
    handleStepChange();
    return () => {
      clearTimeout(currentTimeOut);
    };
  }, [stepIndex]);

  function handleStepChange() {
    if (!steps || !auto || !autoTime || autoTime < 1) return;

    if (currentTimeOut) {
      clearTimeout(currentTimeOut);
    }
    const timeOut = setTimeout(() => {
      let newStepIndex = stepIndex + 1;
      if (newStepIndex >= steps.length) newStepIndex = 0;

      setStepIndex(newStepIndex);
    }, 1000 * autoTime);

    setCurrentTimeOut(timeOut);
    return timeOut;
  }

  return (
    <>
      <div className={styles.Steps} {...sbEditable(blok)}>
        <div className={styles.StepItems}>
          {steps &&
            steps.map((item: any, index: number) => {
              const itemProgressStyle: any = {
                "--progressTime":
                  stepIndex === index ? `${autoTime || 0}s` : "0s",
              };
              return (
                <div key={`step_${index}`}>
                  <div
                    className={styles.Step}
                    data-active={stepIndex === index}
                    onClick={() => {
                      setStepIndex(index);
                    }}
                  >
                    <div className={styles.Progress}>
                      <div
                        className={styles.ProgressLevel}
                        style={itemProgressStyle}
                      ></div>
                    </div>
                    <div className={styles.StepContent}>
                      <h4>{item.title}</h4>
                      <p>{item.excerpt}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {currentItem && (
          <div>
            <img
              className={styles.StepImage}
              alt={currentItem.title + " Image"}
              width="100%"
              height={"auto"}
              src={currentItem.image.filename}
            />
          </div>
        )}
      </div>
      <div className={styles.StepsMobile} {...sbEditable(blok)}>
        <div className={styles.StepItems}>
          {steps &&
            steps.map((item: any, index: number) => {
              return (
                <div key={`step_${index}`}>
                  <div
                    className={styles.Step}
                    data-active={stepIndex === index}
                    onClick={() => {
                      setStepIndex(index);
                    }}
                  >
                    <img
                      className={styles.StepImage}
                      alt={item.title + " Image"}
                      src={item.image.filename}
                      width="450px"
                      height={"auto"}
                    />
                    <div className={styles.StepContent}>
                      <h4>{item.title}</h4>
                      <p>{item.excerpt}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BlokSteps;
