import { FC, useEffect, useState } from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import { cleanVars } from "../../../Theme";
import { useMediaQuery } from "usehooks-ts";
import { emptyString } from "../content/Content";

interface Schema {
  height: string;
  heightMobile: string;
}

interface Props {
  blok: Schema;
}

export function isNumber(input: string): boolean {
  return !isNaN(Number(input));
}

export function fixMessurement(input?: string | any): string | undefined {
  if (!input) return undefined;
  return cleanVars(isNumber(input) ? input + "px" : input);
}

const BlokSpacer: FC<Props> = ({ blok }) => {
  const mobile = useMediaQuery("(max-width: 1000px)");
  const [margin, setMargin] = useState<string | undefined>(undefined);
  useEffect(() => {
    const H = fixMessurement(blok.height);

    const mobileH = fixMessurement(blok.heightMobile);

    if (mobile && !emptyString(mobileH)) {
      setMargin(mobileH);
    } else {
      setMargin(H);
    }
  }, [mobile, blok]);
  return (
    <div
      {...sbEditable(blok)}
      data-mobile={mobile}
      data-height={blok.height}
      data-height-mobile={blok.heightMobile}
      data-margin={margin}
      data-clean={cleanVars(margin)}
      data-use-mobile={mobile && !emptyString(blok.heightMobile)}
      style={{
        marginTop: cleanVars(margin),
      }}
    />
  );
};

export default BlokSpacer;
